import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import header from './media/Gaidamak_Header_Presentation 1.jpg';
import LogoCard from './media/Logo_Gaydamak 1.jpg';
import Presentation from './media/Gaidamak_Site_Presentation 1.jpg';
import Icon1 from './media/Gay_Icon_1.svg';
import Icon2 from './media/Gay_Icon_2.svg';
import Icon3 from './media/Gay_Icon_3.svg';
import Icon4 from './media/Gay_Icon_4.svg';
import Icon5 from './media/Gay_Icon_5.svg';
import Icon6 from './media/Gay_Icon_6.svg';
import Flyer1 from './media/Flyer_Gaydamak_1.jpg';
import Flyer2 from './media/Flyer_Gaydamak_2.jpg';

function Gaidamak({ classes }) {
  return (
    <div>
      <div className={classes.header} />
      <Container>
        <Row>
          <Col>
            <h2 className={classes.h2}>Gaydamak Baumpflege</h2>
            <div className='text-center mt-3'>
              <span className={classes.sub}>
                Logo Design, Webdesign (Landingpage) und Flyer
              </span>
            </div>
            <div className='text-center mt-3 pt-5'>
              <span className={classes.sub2}>Logo</span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={classes.logoCard} />
      <Container className='mb-2 pb-4'>
        <Row>
          <Col>
            <div className='text-center mt-3 pt-5'>
              <span className={classes.sub2}>Webdesign</span>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className={classes.container}>
        <Container>
          <Row>
            <Col>
              <img className='img-fluid' src={Presentation} alt='' />
            </Col>
          </Row>
        </Container>
      </Container>
      <div className='text-center mt-3 pt-5'>
        <span className={classes.sub2}>Video</span>
      </div>
      <div className='text-center mt-3 font-weight-lighter mb-2 pb-4'>
        <span className={classes.sub3}>
          Für die Homepage wurde ein Hintergrundvideo gedreht.
        </span>
      </div>
      <div className='embed-responsive embed-responsive-16by9'>
        <iframe
          title='video'
          className='embed-responsive-item'
          //src='https://www.youtube.com/embed/OUhAn35sQNA?modestbranding=1&iv_load_policy=3'
          src='https://player.vimeo.com/video/375415587?title=0&byline=0&portrait=0&background=1'
          allowFullScreen
          frameBorder='0'
          style={{ backgroundColor: '#51402c' }}
        />
      </div>
      <Container className='mb-2 pb-4'>
        <Row>
          <Col>
            <div className='text-center mt-3 pt-5'>
              <span className={classes.sub2}>Icons</span>
            </div>
            <div className='text-center mt-3 font-weight-lighter mb-2 pb-4'>
              <span className={classes.sub3}>
                Für die Gestaltung der Homepage und Flyer des Kunden wurden
                sechs einzigartige Symbole erstellt.
              </span>
            </div>
          </Col>
        </Row>
        <Row className='mt-2 mt-4'>
          <Col md={{ size: 2, offset: 3 }} xs='6' className='mb-2 pb-4'>
            <img src={Icon1} alt='' />
          </Col>
          <Col md='2' xs='6' className='mb-2 pb-4'>
            <img src={Icon2} alt='' />
          </Col>
          <Col md='2' xs='6' className='mb-2 pb-4'>
            <img src={Icon3} alt='' />
          </Col>
          <Col md={{ size: 2, offset: 3 }} xs='6' className='mb-2 pb-4'>
            <img src={Icon4} alt='' />
          </Col>
          <Col md='2' xs='6' className='mb-2 pb-4'>
            <img src={Icon5} alt='' />
          </Col>
          <Col md='2' xs='6' className='mb-2 pb-4'>
            <img src={Icon6} alt='' />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='text-center mt-2 pt-4'>
              <span className={classes.sub2}>Flyer</span>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className={classes.container}>
        <Container>
          <Row>
            <Col>
              <img className='img-fluid' src={Flyer1} alt='' />
              <img className='img-fluid' src={Flyer2} alt='' />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

const styles = theme => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center'
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0]
  },
  container: {
    backgroundColor: '#F3F3F3'
  },
  logoCard: {
    height: 530,
    backgroundImage: `url("${LogoCard}")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold'
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline'
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100
  }
});

export default injectSheet(styles)(Gaidamak);
