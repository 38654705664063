import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import injectSheet from 'react-jss';
import header from './Santino_Header.svg';
import logo from './Santino_Logo.svg';
import Santino_1 from './Santino_1.jpg';
import Santino_2 from './Santino_2.jpg';
import Santino_3 from './Santino_3.jpg';
import Santino_7 from './Santino_7.jpg';
import Santino_4 from './Santino_4.jpg';
import Santino_5 from './Santino_5.jpg';
import Santino_6 from './Santino_6.jpg';
import Logos from '../../Logos';

function Santino({ classes, toggle }) {
  return (
    <div>
      <div className={classes.header} />
      <h2 className={classes.h2}>Cafeteria Santino</h2>
      <div className='text-center mt-3 mb-3 pb-5'>
        <span className={classes.sub}>Logo und Corporate Design</span>
      </div>
      <div className='text-center' style={{ marginBottom: 40 }}>
        <span className={classes.sub2}>Logo</span>
      </div>
      <Container>
        <Row>
          <Col
            md={{
              offset: 4,
              size: 4,
            }}
          >
            <img src={logo} alt='' className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <div className='text-center mt-3 pt-5' style={{ marginBottom: 40 }}>
        <span className={classes.sub2}>Corporate Design</span>
      </div>
      <Container fluid style={{ backgroundColor: '#D6C4B6' }}>
        <Container>
          <Row>
            <Col>
              <img alt='' src={Santino_1} className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='my-3 py-5'>
        <Row>
          <Col
            md={{
              offset: 2,
              size: 8,
            }}
          >
            <img alt='' src={Santino_2} className='img-fluid' />
          </Col>
        </Row>
        <Row>
          <Col
            md={{
              offset: 1,
              size: 10,
            }}
          >
            <img alt='' src={Santino_3} className='img-fluid' />
          </Col>
        </Row>
        <Row>
          <Col
            md={{
              offset: 1,
              size: 10,
            }}
          >
            <img alt='' src={Santino_7} className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <img alt='' src={Santino_4} className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <Container className='my-3 py-5'>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <img alt='' src={Santino_5} className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#D6C4B6' }}>
        <Container>
          <Row>
            <Col>
              <img alt='' src={Santino_6} className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </Container>
      <div style={{ paddingBottom: 40 }}>
        <div className='text-center mt-3 pt-5 container'>
          <div className={classes.mainText}>
            <span>
              Wünschen Sie sich ein professionelles und interessantes Logo?
              <br />
              Kontaktieren Sie uns einfach und erhalten Sie ein spezielles
              Angebot mit 30% Rabatt:
            </span>
          </div>
          <AnchorLink
            className={classes.btn}
            href='#form'
            offset='94'
            onClick={toggle}
          >
            Angebot erhalten
          </AnchorLink>
          <div
            style={{
              color: '#BFA260',
              fontSize: 14,
              wontWeight: '100',
              marginTop: 40,
              marginBottom: 40,
            }}
          >
            (Das Angebot ist begrenzt)
          </div>
        </div>
        <Logos />
      </div>
    </div>
  );
}

const styles = (theme) => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center',
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0],
  },
  container: {
    backgroundColor: '#DADADA',
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold',
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline',
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100,
  },
  mainText: {
    fontSize: 26,
    lineHeight: '46px',
    fontWeight: 300,
    '& span': {
      color: '#BFA260',
    },
  },
  btn: { ...theme.button.gold, marginTop: 40 },
});

export default injectSheet(styles)(Santino);
