import React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendForm } from '../ducks/app';
import { Container, Col, Row, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { TextField, RadiosField } from '../fields';
import Tooltip from '../components/Tooltip';
import send from '../media/send.jpg';
import emailValidator from 'email-validator';

class FormSection extends React.Component {
  onSubmit = (values) => {
    return this.props.actions.sendForm(values);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Container className='mb-3 pb-5'>
          <FormConnected classes={classes} onSubmit={this.onSubmit} />
        </Container>
      </div>
    );
  }
}

const scrollToTop = () => {
  const anchor = document.getElementById('forma');
  const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
  window.scroll({
    top: offsetTop - 94,
    behavior: 'smooth',
  });
};

class Form extends React.Component {
  componentDidUpdate(prevProps) {
    const { submitSucceeded } = this.props;
    if (submitSucceeded !== prevProps.submitSucceeded) {
      scrollToTop();
    }
  }

  render() {
    const {
      handleSubmit,
      classes,
      submitSucceeded,
      reset,
      submitFailed,
      error,
    } = this.props;
    return (
      <Row>
        <Col id='forma'>
          <h2 className={classes.h2}>Angebot erhalten</h2>
          {submitSucceeded ? (
            <div>
              <div className='my-3 py-5'>
                <img src={send} alt='' className='d-block mx-auto' />
              </div>
              <div className='text-center mb-3 pb-5'>
                <b>Vielen Dank!</b>
                <div className='font-weight-lighter'>
                  Ihre Nachricht wurde erfolgreich gesendet.
                </div>
                <div className='font-weight-lighter'>
                  Bald melden wir uns bei Ihnen.
                </div>
              </div>
              <div className='text-center'>
                <button type='button' className={classes.btn} onClick={reset}>
                  Angebot erhalten
                </button>
              </div>
            </div>
          ) : (
            <div>
              {submitFailed ? (
                <Row>
                  <Col className={classes.error} md={{ size: 6, offset: 3 }}>
                    Einige Felder wurden nicht ausgefüllt oder wurden falsch
                    ausgefüllt. Bitte prüfen Sie Ihre Angaben
                  </Col>
                </Row>
              ) : (
                <div className={classes.subh2}>
                  {/* <b className='text-uppercase font-weight-bold'>
                    Frühlingsangebot!
                  </b>
                  <br />
                  <br />
                  Gestaltung Ihres Marketings
                  <div className='mt-3'>
                    Nur bis 10. April Rabatte auf alle Arten von Design,{' '}
                    <b className='font-weight-bold'>bis zu 30%</b>**
                  </div> */}
                  <div className='mt-3'>
                    Füllen Sie das kurze Formular unten aus und erhalten Sie ein
                    spezielles Angebot:
                  </div>
                </div>
              )}
              <FormGroup row>
                <Label className='text-left text-md-right' md='4'>
                  Firma*{' '}
                  <Tooltip target='Firma' text='Name Ihres Unternehmens'>
                    <span className={classes.tooltip}>?</span>
                  </Tooltip>
                </Label>
                <Col md='5'>
                  <Field name='firma' component={TextField} bsSize='sm' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md='6' className='text-left text-md-right'>
                  <Label>
                    Anrede*{' '}
                    <Tooltip target='Anrede' text='Anrede'>
                      <span className={classes.tooltip}>?</span>
                    </Tooltip>
                  </Label>
                </Col>
                <Col md='3'>
                  <Field
                    name='anrede'
                    component={RadiosField}
                    bsSize='sm'
                    options={[
                      { code: 'male', name: 'Herr' },
                      { code: 'female', name: 'Frau' },
                    ]}
                    className='d-flex'
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className='text-left text-md-right' md='4'>
                  Name*{' '}
                  <Tooltip target='Name' text='Ihr Vor-und Nachname'>
                    <span className={classes.tooltip}>?</span>
                  </Tooltip>
                </Label>
                <Col md='5'>
                  <Field name='name' component={TextField} bsSize='sm' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className='text-left text-md-right' md='7'>
                  Plz*{' '}
                  <Tooltip target='Plz' text='Postleitzahl'>
                    <span className={classes.tooltip}>?</span>
                  </Tooltip>
                </Label>
                <Col md='2'>
                  <Field name='plz' component={TextField} bsSize='sm' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className='text-left text-md-right' md='6'>
                  Stadt*{' '}
                  <Tooltip target='Stadt' text='Ihre Stadt'>
                    <span className={classes.tooltip}>?</span>
                  </Tooltip>
                </Label>
                <Col md='3'>
                  <Field name='stadt' component={TextField} bsSize='sm' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className='text-left text-md-right' md='4'>
                  Telefon*{' '}
                  <Tooltip
                    target='Telefon'
                    text='Ihr Handy- oder Festnetznummer'
                  >
                    <span className={classes.tooltip}>?</span>
                  </Tooltip>
                </Label>
                <Col md='5'>
                  <Field name='telefon' component={TextField} bsSize='sm' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className='text-left text-md-right' md='4'>
                  E-mail*{' '}
                  <Tooltip
                    target='Email'
                    text='Bitte geben Sie Ihre E-Mail-Adresse an, um ein Angebot zu erhalten'
                  >
                    <span className={classes.tooltip}>?</span>
                  </Tooltip>
                </Label>
                <Col md='5'>
                  <Field name='email' component={TextField} bsSize='sm' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className='text-left text-md-right' md='4'>
                  Nachricht*{' '}
                  <Tooltip
                    target='Nachricht'
                    text='Bitte beschreiben Sie Ihre Anfrage so genau wie möglich'
                  >
                    <span className={classes.tooltip}>?</span>
                  </Tooltip>
                </Label>
                <Col md='5'>
                  <Field
                    name='nachricht'
                    component={TextField}
                    bsSize='sm'
                    type='textarea'
                    placeholder='Was benötigen Sie? z.B. Logo oder HomePage (Landingpage) usw'
                    rows={4}
                  />
                </Col>
              </FormGroup>
              <div className='text-center'>
                <button
                  type='button'
                  className={classes.btn}
                  onClick={handleSubmit}
                >
                  Angebot erhalten
                </button>
                <div className='mt-3 pt-3'>
                  <span className={classes.subh3}>
                    * - Pflichtfelder
                    {/* <br />
                     ** - Das Angebot ist begrenzt */}
                  </span>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

const validate = (values) => {
  const errors = {};

  const { firma, anrede, name, plz, stadt, email, telefon, nachricht } = values;
  if (!plz) {
    errors.plz = 'plz';
  } else {
    if (!/^[0-9]+$/.test(plz)) {
      errors.plz = 'plz';
    }
    if (plz.length < 5) {
      errors.plz = 'plz';
    }
  }
  if (!stadt) {
    errors.stadt = 'stadt';
  } else {
    if (!/^[a-zA-Z.,-\/()\x7f-\xff\s]+$/.test(stadt)) {
      errors.stadt = 'stadt';
    }
  }
  if (!telefon) {
    errors.telefon = 'telefon';
  } else {
    if (!/^[0-9\s-()+]+$/.test(telefon)) {
      errors.telefon = 'telefon';
    }
  }
  if (!nachricht) {
    errors.nachricht = 'nachricht';
  }
  if (!firma) {
    errors.firma = 'firma';
  }
  if (!anrede || !anrede.code) {
    errors.anrede = 'anrede';
  }
  if (!name) {
    errors.name = 'name';
  } else {
    const arrName = name.split(' ');
    if (arrName.length < 2 || arrName[0].length < 2 || arrName[1].length < 2) {
      errors.name = 'name';
    }
    if (/[0-9]/.test(name)) {
      errors.name = 'name';
    }
  }
  if (!email || !emailValidator.validate(email)) {
    errors.email = 'email';
  }

  return errors;
};

let FormConnected = reduxForm({
  form: 'feed',
  validate,
  onSubmitFail: () => {
    scrollToTop();
  },
})(Form);

const style = (theme) => ({
  root: {
    '& label': {
      color: '#000',
      fontWeight: 300,
    },
    '& .form-group': {
      marginBottom: '2rem',
    },
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0],
  },
  subh2: {
    marginTop: '1rem',
    marginBottom: '4rem',
    fontSize: 18,
    fontWeight: 300,
    lineHeight: '21px',
    textAlign: 'center',
  },
  subh3: {
    marginTop: '1rem',
    marginBottom: '4rem',
    fontSize: 18,
    fontWeight: 100,
    lineHeight: '21px',
    textAlign: 'center',
  },
  btn: theme.button.dark,
  tooltip: {
    display: 'inline-block',
    width: 22,
    height: 22,
    borderRadius: '50%',
    border: '1px solid rgb(206, 212, 218)',
    textAlign: 'center',
    lineHeight: '22px',
    fontSize: 14,
    position: 'relative',
    top: -2,
  },
  error: {
    marginTop: 20,
    marginBottom: 60,
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'center',
    color: '#D03838',
  },
});

const mapStateToProps = ({ app }) => ({
  formSending: app.formSending,
  formSendingError: app.formSendingError,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ sendForm }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(style)(FormSection));
