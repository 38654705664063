import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
// иконки
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'; //prettier-ignore

export default function Tooltip({ target, text, children }) {
  return (
    <>
      {!children ? (
        <FontAwesomeIcon
          tabIndex={-1}
          id={target}
          icon={faQuestionCircle}
          className='text-primary'
          onClick={e => e.preventDefault()}
        />
      ) : (
        React.cloneElement(children, {
          tabIndex: -1,
          id: target
        })
      )}
      <UncontrolledTooltip
        target={target}
        trigger='hover focus'
        className='tip'
        innerClassName='text-left'
        container='#root'
        fade={false}
      >
        {text}
      </UncontrolledTooltip>
    </>
  );
}
