import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import header from './media/header.jpg';
import D from './media//3D.jpg';
import D1 from './media/3D1.jpg';
import D2 from './media/3D2.jpg';
import box from './media/box.jpg';

function Parfum({ classes }) {
  return (
    <div>
      <div className={classes.header} />
      <h2 className={classes.h2}>Parfüm</h2>
      <div className='text-center mt-3 mb-3 pb-5'>
        <span className={classes.sub}>3D-Modellierung</span>
      </div>
      <Container fluid className={classes.container}>
        <Container>
          <Row>
            <Col>
              <img src={D} alt='' className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='mt-4'>
        <Row>
          <Col md='6' className='mb-4'>
            <img src={D1} alt='' className='img-fluid' />
          </Col>
          <Col md='6' className='mb-4'>
            <img src={D2} alt='' className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <Container fluid className={classes.container}>
        <Container>
          <Row>
            <Col>
              <img src={box} alt='' className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='font-weight-lighter my-3 py-4'>
        <Row>
          <Col>
            <span className={classes.brown}>
              Das Modell wurde von unserem 3D-Modellierer im Rahmen einer
              Seminararbeit erstellt. Dieses Projekt ist kein Auftrag von Loewe.
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const styles = theme => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center'
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0]
  },
  container: {
    backgroundColor: '#DADADA'
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold'
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline'
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100
  }
});

export default injectSheet(styles)(Parfum);
