export const MENU = [
  {
    text: 'Kompetenzen',
    href: '#kompetenzen',
    offset: '94',
  },
  {
    text: 'Portfolio',
    href: '#portfolio',
    offset: '94',
  },
  {
    text: 'Nützliches',
    href: '#logos',
    offset: '94',
  },
  {
    text: 'Über Uns',
    href: '#about',
    offset: '94',
  },
  {
    text: 'Angebot',
    href: '#form',
    offset: '94',
  },
  {
    text: 'Kontakt',
    href: '#contact',
    offset: '94',
  },
];
