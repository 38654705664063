import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import header from './media/3D_Kamin_Header.jpg';
import Kamin1 from './media/3D_Kamin_1.jpg';
import Kamin2 from './media/3D_Kamin_2.jpg';
import Kamin3 from './media/3D_Kamin_3.jpg';
import Kamin4 from './media/3D_Kamin_4.jpg';
import Kamin5 from './media/3D_Kamin_5.jpg';

function Kamin({ classes }) {
  return (
    <div>
      <div className={classes.header} />
      <h2 className={classes.h2}>Kamin</h2>
      <div className='text-center mt-3 mb-3 pb-5'>
        <span className={classes.sub}>3D-Modellierung</span>
      </div>
      <Container>
        <Row>
          <Col style={{ height: 278 }}>
            <img src={Kamin1} alt='' className={classes.cropp} />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='mt-2 pt-3'>
          <Col>
            <img src={Kamin2} alt='' className='img-fluid' />
          </Col>
        </Row>
        <Row className='mt-2 pt-3'>
          <Col md='6' className='mb-2 pb-3'>
            <img src={Kamin3} alt='' className='img-fluid' />
          </Col>
          <Col md='6' className='mb-2 pb-3'>
            <img src={Kamin4} alt='' className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col style={{ height: 625 }}>
            <img src={Kamin5} alt='' className={classes.cropp} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const styles = theme => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center'
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0]
  },
  container: {
    backgroundColor: '#DADADA'
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold'
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline'
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100
  },
  cropp: {
    position: 'absolute',
    maxWidth: 1110,
    top: 0,
    left: '50%',
    transform: 'translate(-50%)'
  }
});

export default injectSheet(styles)(Kamin);
