import React from 'react';
import { Logo } from '../../media/dark';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import KolibriLeft from '../../media/logos/kalibri_left.jpg';
import KolibriRight from '../../media/logos/kalibri_right.jpg';

function LogoContent({ classes, toggle }) {
  return (
    <div>
      <Container fluid>
        <h2 className={classNames(classes.h2Dark, 'text-center mb-3')}>
          Logo / Corporate Designs
        </h2>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className={classes.cardText}>
              <img src={Logo} className='img-fluid' alt='' />
              <div className='mt-3'>
                Wir entwickeln für Sie und Ihre Firma ein einzigartiges
                Markenzeichen (Logo), das nach allen Regeln des Logo-Designs
                aufgebaut ist. Darüber hinaus können wir Ihnen ein umfassendes
                Design der gesamten Marketing-Attribute Ihrer Firma anbieten,
                wie:
              </div>
              <div className='my-3 py-3'>
                Kopfbögen / Briefköpfe
                <br />
                •<br />
                Dokumenten-Mappen
                <br />
                •<br />
                Visitenkarten
                <br />
                •<br />
                Farbkanons
                <br />
                •<br />
                Souvenirprodukte (Stift, Becher, T-Shirt, Notizbuch usw.)
                <br />
                •<br />
                Vollständige Corporate Identity Guides
                <br />
                <br />
                usw...
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <BrownAction onClick={toggle} />
      <WhiteAction onClick={toggle} />
      <ArticleAction onClick={toggle} />
    </div>
  );
}

const useBrownStyles = createUseStyles((theme) => ({
  root: {
    padding: [45, 0],
    backgroundColor: '#3F3F3F',
  },
  textBlock: {
    color: '#BFA260',
    lineHeight: '38px',
    fontWeight: 300,
    textAlign: 'center',
  },
  btn: theme.button.gold,
}));

const BrownAction = ({ onClick }) => {
  const classes = useBrownStyles();
  return (
    <Container fluid className={classes.root}>
      <Container>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <div className={classes.textBlock}>
              Wünschen Sie sich ein professionelles und interessantes Logo?
            </div>
            <div className={classes.textBlock}>
              Kontaktieren Sie uns einfach und erhalten Sie ein spezielles
              Angebot mit 30% Rabatt:
            </div>
            <div className={classNames('text-center mt-4 pt-1')}>
              <AnchorLink
                className={classes.btn}
                href='#form'
                onClick={onClick}
                offset='94'
              >
                Angebot erhalten
              </AnchorLink>
              <div
                style={{
                  color: '#BFA260',
                  fontSize: 14,
                  wontWeight: '100',
                  marginTop: 40,
                  marginBottom: 40,
                }}
              >
                (Das Angebot ist begrenzt)
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const useWhiteStyles = createUseStyles((theme) => ({
  root: {
    padding: [45, 0],
    backgroundColor: '#FFFFFF',
  },
  textBlock: {
    color: '#3F3F3F',
    lineHeight: '38px',
    fontWeight: 300,
    textAlign: 'center',
  },
  btn: theme.button.dark,
}));

const WhiteAction = ({ onClick }) => {
  const classes = useWhiteStyles();
  return (
    <Container fluid className={classes.root}>
      <Container>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <div className={classes.textBlock}>
              Schauen Sie sich unser Portfolio an:
            </div>
            <div className={classNames('text-center mt-4 pt-1')}>
              <AnchorLink
                className={classes.btn}
                href='#portfolio'
                onClick={onClick}
                offset='94'
              >
                PORTFOLIO
              </AnchorLink>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const useArticleStyles = createUseStyles((theme) => ({
  root: {
    paddingTop: 45,
    backgroundColor: '#3F3F3F',
  },
  title: {
    fontWeight: 100,
    lineHeight: '21px',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  text: {
    color: '#FFFFFF',
    lineHeight: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  btn: theme.button.gold,
}));

const ArticleAction = ({ onClick }) => {
  const classes = useArticleStyles();
  return (
    <div className={classes.root}>
      <Container fluid>
        <Container>
          <Row>
            <Col>
              <div className={classes.title}>Artikel</div>
              <div className={classes.text}>Wie werden Logos erstellt?</div>
              <Link to='/portfolio/Logos'>
                <div className={classNames('text-center my-4 py-1')}>
                  <button className={classes.btn}>erfahren</button>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row noGutters>
          <Col md={6}>
            <img
              src={KolibriRight}
              alt=''
              className='img-fluid d-block mx-auto'
            />
          </Col>
          <Col md={6}>
            <img
              src={KolibriLeft}
              alt=''
              className='img-fluid d-block mx-auto'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LogoContent;
