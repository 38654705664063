import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import GaidamakCover from '../media/covers/1_Gaidamak_cover.jpg';
import MenuCover from '../media/covers/2_Menu_cover.jpg';
import FurnitureCover from '../media/covers/3_Furniture_cover.jpg';
import ParfumCover from '../media/covers/4_Parfum_cover.jpg';
import MobelCover from '../media/covers/5_Mobel_cover.jpg';
import KaminCover from '../media/covers/6_Kamin_cover.jpg';
import VolleyCover from '../media/covers/7_Volley_cover.jpg';
import Poster1Cover from '../media/covers/8_Poster1_cover.jpg';
import Poster2Cover from '../media/covers/9_Poster2_cover.jpg';
import AquaCover from '../Page/Demo/Aqua/Aqua_Cover.svg';
import SantinoCover from '../Page/Demo/Santino/Santino_Cover2.svg';
import GarudaCover from '../Page/Demo/Garuda/Garuda_Cover.svg';

const Portfolio = ({ classes }) => {
  return (
    <Container>
      <Row>
        <Col>
          <h2 className={classes.h2}>Portfolio</h2>
        </Col>
      </Row>
      <Row noGutters>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Aqua'>
            <img className={classes.preview} src={AquaCover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Santino'>
            <img className={classes.preview} src={SantinoCover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Garuda'>
            <img className={classes.preview} src={GarudaCover} alt='' />
          </Link>
        </Col>
      </Row>
      <Row noGutters>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Gaidamak'>
            <img className={classes.preview} src={GaidamakCover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Menu'>
            <img className={classes.preview} src={MenuCover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Furniture'>
            <img className={classes.preview} src={FurnitureCover} alt='' />
          </Link>
        </Col>
      </Row>
      <Row noGutters>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Parfum'>
            <img className={classes.preview} src={ParfumCover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Mobel'>
            <img className={classes.preview} src={MobelCover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Kamin'>
            <img className={classes.preview} src={KaminCover} alt='' />
          </Link>
        </Col>
      </Row>
      <Row noGutters>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Volley'>
            <img className={classes.preview} src={VolleyCover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Poster1'>
            <img className={classes.preview} src={Poster1Cover} alt='' />
          </Link>
        </Col>
        <Col md={4} className='overflow-hidden'>
          <Link to='/portfolio/Poster2'>
            <img className={classes.preview} src={Poster2Cover} alt='' />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const style = (theme) => ({
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0],
  },
  preview: {
    '&:hover': {
      transform: 'scale(1.07)',
    },
    transition: '0.3s',
    maxWidth: '100%',
    cursor: 'pointer',
  },
});

export default injectSheet(style)(Portfolio);
