import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import {
  getTime,
  format,
  isDate,
  isValid,
  isBefore,
  isAfter,
  isEqual,
  parse
} from 'date-fns';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');

class InputBase extends React.Component {
  state = {
    hover: false
  };

  checkInterval = parsedDate => {
    const { minDate, maxDate } = this.props;
    let validMinDate = true;
    let validMaxDate = true;
    if (
      !!minDate &&
      isValid(minDate) &&
      (!isBefore(minDate, parsedDate) && !isEqual(minDate, parsedDate))
    ) {
      validMinDate = false;
    }
    if (
      !!maxDate &&
      isValid(maxDate) &&
      (!isAfter(maxDate, parsedDate) && !isEqual(maxDate, parsedDate))
    ) {
      validMaxDate = false;
    }
    return { validMinDate, validMaxDate };
  };

  componentDidUpdate(prevProps) {
    const {
      minDate,
      maxDate,
      currentString,
      inputProps: { input }
    } = this.props;
    if (
      (!isEqual(prevProps.minDate, minDate) || !isEqual(prevProps.maxDate, maxDate)) && // prettier-ignore
      prevProps.currentString === currentString && !!currentString // prettier-ignore
    ) {
      let parsedDate = parse(currentString, 'dd.MM.yyyy', 0);
      if (isValid(parsedDate)) {
        const { validMinDate, validMaxDate } = this.checkInterval(parsedDate);
        if (validMinDate && validMaxDate) {
          input.onChange(getTime(parsedDate));
          return;
        }
        if (!validMinDate) {
          input.onChange(
            `Дата не может быть меньше ${format(minDate, 'dd.MM.yyyy')}`
          );
        }
        if (!validMaxDate) {
          input.onChange(
            `Дата не может быть больше ${format(maxDate, 'dd.MM.yyyy')}`
          );
        }
      }
    }
  }

  // Срабатывает при вводе текста в input
  onChangeHandler = e => {
    const {
      onChange,
      inputProps: { input },
      setCurrentString,
      minDate,
      maxDate
    } = this.props;

    // Введенное значение
    const typedString = e.target.value;

    // Установка в state DatePicker`a
    setCurrentString(e.target.value);
    // Если введена корректная дата, вызываем onChange DatePicker`а
    // в свою очередь DatePicker вызовет свой метод DatePicker.handleChange.
    // Иначе затираем значение даты в redux-form
    let parsedDate = parse(typedString, 'dd.MM.yyyy', 0);
    if (isValid(parsedDate)) {
      // Проверить на минимальную и максимальную дату
      const { validMinDate, validMaxDate } = this.checkInterval(parsedDate);
      onChange(e);
      if (!validMinDate) {
        input.onChange(
          `Дата не может быть меньше ${format(minDate, 'dd.MM.yyyy')}`
        );
      }
      if (!validMaxDate) {
        input.onChange(
          `Дата не может быть больше ${format(maxDate, 'dd.MM.yyyy')}`
        );
      }
    } else {
      input.onChange(undefined);
    }
  };

  render() {
    const {
      currentString,
      calendarIsOpen,
      inputProps: { input, meta, autoFocus },
      onClick,
      placeholder,
      isReadonly,
      isDisabled,
      ...custom
    } = this.props;

    const error = getError(meta);
    const { hover } = this.state;

    return (
      <InputGroup>
        <MaskedInput
          id={custom.id || input.name.replace(/\./g, '-')}
          value={currentString}
          onChange={this.onChangeHandler}
          onFocus={input.onFocus}
          onBlur={e => input.onBlur(input.value)}
          invalid={!!error}
          placeholder={placeholder}
          readOnly={isReadonly}
          disabled={isDisabled}
          autoComplete='off'
          autoFocus={!!autoFocus || false}
          keepCharPositions
          mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
          pipe={autoCorrectedDatePipe}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          render={(ref, props) => <Input innerRef={ref} {...props} />}
          inputMode='numeric'
        />
        <InputGroupAddon addonType='append'>
          <Button
            onBlur={this.onButtonBlur}
            className='calendar-button'
            color={!!error ? 'danger' : 'primary'}
            outline
            onClick={onClick} // Вызывается внутренний метод DatePicker`a, который откроет календарь
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </Button>
        </InputGroupAddon>
        <ErrorTooltip
          error={!calendarIsOpen && error}
          meta={meta}
          hover={hover}
          target={custom.id || input.name.replace(/\./g, '-')}
        />
      </InputGroup>
    );
  }
}

class DateField extends React.Component {
  static defaultProps = {
    minDate: new Date(1900, 0, 1, 0, 0, 0, 0),
    maxDate: new Date(2100, 0, 1, 0, 0, 0, 0),
    disabled: false,
    readOnly: false,
    popperPlacement: 'bottom-end'
  };

  static propTypes = {
    minDate: PropTypes.instanceOf(Date).isRequired,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired
  };

  state = {
    currentString:
      !isNaN(this.props.input.value) &&
      isValid(new Date(this.props.input.value))
        ? format(new Date(this.props.input.value), 'dd.MM.yyyy')
        : '', //Введенное в input значение
    calendarIsOpen: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { input, meta } = this.props;
    // При вызове redux-form reset() нужно отчистить input
    let currentString = '';
    // Если предыдущее значение отличалось от initial, а теперь не отличается, значит произошел reset()
    if (!prevProps.meta.pristine && meta.pristine && !prevProps.meta.active) {
      if (!isNaN(input.value) && isValid(new Date(input.value))) {
        currentString = format(input.value, 'dd.MM.yyyy');
      }
      this.setState({ currentString });
    }
    // Автозаполненное поле
    if (meta.autofilled && prevProps.input.value !== input.value) {
      if (!isNaN(input.value) && isValid(new Date(input.value))) {
        currentString = format(input.value, 'dd.MM.yyyy');
      }
      this.setState({ currentString });
    }
  }

  // метод устанавливает введенное в input значение в state
  setCurrentString = currentString => {
    this.setState({ currentString });
  };

  // Основной метод для сохранения даты в redux-form
  handleChange = (date, e) => {
    this.props.input.onChange(getTime(date));
    this.setCurrentString(format(date, 'dd.MM.yyyy'));
  };

  // Используется только для установки onFocus(redux-form)
  // при нажатии на иконку календаря
  onInputClick = () => {
    this.setState({ calendarIsOpen: true }, () => {
      this.props.input.onFocus();
    });
  };

  // Используется только для установки onBlur(redux-form),
  // если дата выбрана из календаря
  onDaySelect = (date, e) => {
    const fromInputField = !!e.target.value;
    if (!fromInputField) {
      this.setState({ calendarIsOpen: false }, () => {
        this.props.input.onBlur(getTime(date));
      });
    }
  };

  // Используется только для установки onBlur(redux-form),
  // если был открыт календарь и закрыт нажатием вне его
  onClickOutside = () => {
    this.setState({ calendarIsOpen: false }, () => {
      this.props.input.onBlur(this.props.input.value);
    });
  };

  getSelected = value => {
    // value может быть сообщением об ощибке, т.е. строкой. Тогда IE 11 умирает
    return !isNaN(value) && isDate(new Date(value)) ? new Date(value) : null;
  };

  openToDate = value => {
    // value может быть сообщением об ощибке, т.е. строкой. Тогда IE 11 умирает
    const { defaultDate } = this.props;
    return !isNaN(value) && isDate(new Date(value)) ? null : defaultDate;
  };

  render() {
    const {
      input,
      meta,
      minDate,
      maxDate,
      classes,
      disabled,
      readOnly,
      defaultDate,
      popperPlacement,
      ...custom
    } = this.props;
    const { calendarIsOpen, currentString } = this.state;
    return (
      <div className={classes.root}>
        <DatePicker
          {...custom}
          minDate={minDate}
          maxDate={maxDate}
          selected={this.getSelected(input.value)}
          dateFormat='dd.MM.yyyy'
          openToDate={this.openToDate(input.value)}
          onChange={this.handleChange}
          withPortal={false}
          showTimeSelect={false}
          onInputClick={this.onInputClick}
          onClickOutside={this.onClickOutside}
          onSelect={this.onDaySelect}
          placeholderText={custom.placeholder}
          showMonthDropdown
          showYearDropdown
          calendarClassName={classNames(classes.rgsDatepicker, 'shadow')}
          dayClassName={date => classes.rgsDatepickerDay}
          dropdownMode='select'
          disabled={disabled || readOnly}
          popperPlacement={popperPlacement}
          customInput={
            <InputBase
              inputProps={{ input, meta, ...custom }}
              currentString={currentString}
              calendarIsOpen={calendarIsOpen}
              setCurrentString={this.setCurrentString}
              minDate={minDate}
              maxDate={maxDate}
              isDisabled={disabled}
              isReadonly={readOnly}
              classes={classes}
            />
          }
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    '& .react-datepicker-wrapper': {
      display: 'block'
    },
    '& .react-datepicker-popper': {
      zIndex: 9999
    },
    width: '100%' // IE 11 bugfix
  },
  rgsDatepicker: {
    //backgroundColor: theme.colors.danger,
    borderRadius: 0,
    borderColor: 'rgba(0, 0, 0, 0.15)',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    '& .react-datepicker__day-name': {
      width: 30,
      lineHeight: '30px',
      margin: 5
    },
    '& .react-datepicker__navigation:not(.react-datepicker__navigation--years)': {
      top: 15,
      '&:focus': {
        outline: 0
      }
    },
    '& .react-datepicker__current-month': {
      fontWeight: 'normal',
      display: 'none'
    },
    '& .react-datepicker__header__dropdown': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .react-datepicker__month-dropdown': {
      top: 13,
      left: '5%',
      boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important',
      padding: [10, 0],
      borderRadius: 0,
      border: 'none',
      backgroundColor: '#fff',
      '& .react-datepicker__month-option': {
        textAlign: 'left',
        paddingLeft: 40,
        marginLeft: 0,
        marginRight: 0,
        '&:hover': {
          backgroundColor: 'var(--primary)',
          color: '#fff'
        }
      }
    },
    '& .react-datepicker__year-dropdown': {
      top: 13,
      right: '5%',
      left: 'auto',
      boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important',
      padding: [10, 0],
      borderRadius: 0,
      border: 'none',
      backgroundColor: '#fff',
      '& .react-datepicker__year-option': {
        '&:hover': {
          backgroundColor: 'var(--primary)',
          color: '#fff'
        }
      }
    },
    '& .react-datepicker__triangle': {
      // left: 'auto',
      // right: 13,
      // marginLeft: 0
      display: 'none'
    }
  },

  rgsDatepickerDay: {
    width: 30,
    lineHeight: '30px',
    margin: 5,
    '&.react-datepicker__day--keyboard-selected': {
      backgroundColor: '#bf914d'
    },
    '&.react-datepicker__day--selected': {
      backgroundColor: '#bf914d'
    }
  }
});

export default injectSheet(styles)(DateField);
