import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import header from './media/Header_Logo_Artikel.jpg';
import Logo1 from './media/logo1.png';
import Logo2 from './media/logo2.png';
import Logo3 from './media/logo3.png';
import Logo4 from './media/logo4.png';
import Logo5 from './media/logo5.png';
import Logo6 from './media/logo6.png';
import Logo7 from './media/logo7.png';
import Arrow from './media/arrow.svg';
import LogoFinal from './media/logo_final.jpg';

function Logos({ classes, history }) {
  return (
    <div>
      <div className={classes.header} />
      <h1 className={classes.h2}>Wie werden Logos erstellt?</h1>
      <div className='text-center mt-3 mb-3 pb-5'>
        <span className={classes.sub}>Gestaltungsraster von Logos</span>
      </div>
      <Container>
        <Row>
          <Col
            md={{
              size: 10,
              offset: 1,
            }}
          >
            <div
              className={classNames(classes.mainText, classes.mainTextBorder)}
            >
              <span>Logo designen lassen</span> oder{' '}
              <span>Firmenlogo erstellen lassen</span> sind die beliebtesten
              Anfragen, wenn man eine professionelle Logoerstellung braucht.
              Aber wie sieht eigentlich eine professionelle Logogestaltung aus?
              Gibt es Regeln?
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            md={{
              size: 10,
              offset: 1,
            }}
          >
            <h4 className={classes.sub2}>
              In diesem Artikel versuchen wir, diese Fragen zu beantworten.
            </h4>
            <p className={classes.p}>
              Die Geschichte der Marken geht weit zurück in die Vergangenheit,
              und heute gibt es viele Möglichkeiten, ein Logo zu erstellen. Die
              Wahl der Art und Weise, wie ein neues Logo erstellt wird, hängt
              von den Aufgaben des Kunden und des Designers ab.
              <br />
              <br />
              Je nach Branche des Unternehmens, seiner Produkte und
              Dienstleistungen sind unterschiedliche emotionale Stimmungen im
              Logo möglich. Zum Beispiel können Sie einen Tropfen Tinte auf ein
              weißes Blatt fallen lassen und sagen - das ist mein Logo. In
              diesem Fall haben Sie aber auch jedes Recht, es so zu betrachten
              und es gerne zu benutzen. Der Designer muss nur den Tintenfleck
              scannen und im Vektorformat zeichnen. Voilà: Ihr neues Logo ist
              fertig.
              <br />
              <br />
              Das wäre alles so einfach!
              <br />
              <br />
              Der Fall, den ich oben beschrieben habe, kann eher als ideal
              bezeichnet werden. Ich denke, es gibt nicht viele Unternehmen auf
              der Welt, die sich einen gewöhnlichen Tintenfleck in Form eines
              Logos wünschen. Normalerweise ist die Logoerstellung eine Menge
              Arbeit, und erfordert eine lange Suche nach den richtigen Bildern,
              Formen und grafischen Reimen.
              <br />
              <br />
              Ein guter Assistent des Designers auf diesem schwierigen Weg kann
              ein Gestaltungsraster sein.
              <br />
              <br />
              Viele bekannte Marken haben diese Methode bei der Gestaltung Ihres
              Logos verwendet: <br />
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            md={{
              size: 10,
              offset: 1,
            }}
          >
            <Row>
              <Col className='mt-3 pt-5' md={6}>
                <img className='img-fluid' alt='Logo von Apple' src={Logo1} />
                <div className={classes.caption}>Logo von Apple</div>
              </Col>
              <Col className='mt-3 pt-5' md={6}>
                <img className='img-fluid' alt='Logo von Twitter' src={Logo2} />
                <div className={classes.caption}>Logo von Twitter</div>
              </Col>
            </Row>
            <Row className='mt-3 pt-5 mb-3 pb-5'>
              <Col
                md={{
                  offset: 6,
                  size: 6,
                }}
              >
                <div className={classNames(classes.mainText, classes.quote)}>
                  <span>
                    Das Gestaltungsraster von Logos ist eine Logo-Karkasse, die
                    aus Hilfslinien besteht.
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={classes.p}>
                  Ein Gestaltungsraster kann aus verschiedenen geometrischen
                  Formen (Rechtecken, Kreisen usw.) bestehen und
                  unterschiedliche Prinzipien der Wechselwirkung dieser Formen
                  untereinander haben.
                  <br />
                  <br />
                  In diesem Artikel betrachten wir ein Gestaltungsraster
                  basierend auf Kreisen, und das Prinzip der Interaktion
                  zwischen den Elementen des Gitters wird aus den Regeln des
                  Goldenen Schnitts und der Fibonacci-Zahlen übernommen.
                  <br />
                  <br />
                  Zuerst muss man mindestens eine ungefähre Skizze des
                  zukünftigen Zeichens haben. Dies kann sowohl eine Handskizze
                  sein als auch Experimente mit Kurven in einem grafischen
                  Editor sein. Der Charakter unseres Logos ist ein kleiner
                  Kolibri-Vogel. Wir wollen ein einfaches und ausdrucksstarkes
                  Zeichen kreieren (Eine ziemlich häufige Anforderung an Logos,
                  stimmen Sie zu?):
                </p>
              </Col>
            </Row>
            <Row>
              <Col className='my-3 py-5'>
                <img className='img-fluid d-block mx-auto' alt='' src={Logo3} />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={classes.p}>
                  Als Nächstes muss man die Grundlage für ein Gestaltungsraster
                  erstellen. Zu diesem Zweck nehmen wir das Verhältnis der
                  Elemente nach dem Prinzip des Goldenen Schnitts und der
                  Fibonacci-Zahlen:
                </p>
              </Col>
            </Row>
            <Row className='mb-3 pb-5'>
              <Col className='mt-3 pt-5' md={6}>
                <img className='img-fluid' alt='' src={Logo4} />
              </Col>
              <Col className='mt-3 pt-5' md={6}>
                <img className='img-fluid' alt='' src={Logo5} />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={classes.p}>
                  Die Basis unseres Gestaltungsrasters sind Kreise. Wir
                  schreiben sie in die Rechtecke unseres Rahmens ein. Die Zahlen
                  in den Kreisen bedeuten nicht Ihre Sequenznummer (wir haben
                  nur 6 Kreise), sondern das Verhältnis der Kreise zueinander.
                  <br />
                  <br />
                  Wenn beispielsweise Kreis Nummer 13 einen Durchmesser von 130
                  px hat, hat Kreis Nummer 8 einen von 80 px. Ich denke, das
                  Prinzip ist klar. Also:
                </p>
              </Col>
            </Row>
            <Row>
              <Col className='my-3 py-5'>
                <img className='img-fluid d-block mx-auto' alt='' src={Logo6} />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={classes.p}>
                  Dann legen wir unsere 6 Kreise auf die Logo-Skizze. Mit
                  anderen Worten ist dieses Stadium die endgültige Erstellung
                  des Logo-Gestaltungsrasters. Dieser Prozess gibt Ihnen zwar
                  eine mathematische Grundlage als Unterstützung, aber in Erster
                  Linie ist es natürlich Kreativität.
                  <br />
                  <br />
                  Probieren und experimentieren Sie, suchen nach Harmonie und
                  Ausdruckskraft! Aber vor allem, tun Sie es mit Liebe und
                  Aufmerksamkeit wie ich!
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className='text-center my-3 py-5'>
        <iframe
          title='Logo animation'
          src={process.env.PUBLIC_URL + '/Banner/index.html'}
          width='362px'
          maxwidth='100%'
          height='224px'
          frameBorder='0'
        />
      </div>
      <Container>
        <Row>
          <Col
            md={{
              size: 10,
              offset: 1,
            }}
          >
            <Row className='my-3 py-5'>
              <Col
                md={{
                  offset: 6,
                  size: 6,
                }}
              >
                <div className={classNames(classes.mainText, classes.quote)}>
                  <span>
                    Probieren und experimentieren Sie, suchen nach Harmonie und
                    Ausdruckskraft!
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={classes.p}>
                  Die oben beschriebenen Prinzipien helfen Ihnen auch bei der
                  Erstellung einer Schriftart für Ihr Logo.
                  <br />
                  <br />
                  So sieht es in unserem Beispiel aus:
                </p>
              </Col>
            </Row>
            <Row>
              <Col className='my-3 py-5'>
                <img className='img-fluid d-block mx-auto' alt='' src={Logo7} />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={classNames(classes.p, 'text-center')}>
                  Ich wünsche Ihnen viel Erfolg in dieser komplexen, aber
                  unendlich interessanten Arbeit, bei der Schaffung eines
                  echten, unvergesslichen und harmonischen Logos.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <img
                  src={Arrow}
                  alt=''
                  className={classNames('d-block mx-auto', classes.arrow)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className={classes.final}>
        <Row>
          <Col>
            <div
              className={classNames(classes.mainText, 'text-center my-3 py-3')}
            >
              <span>
                Wünschen Sie sich ein professionelles und interessantes Logo?
                <br />
                Kontaktieren Sie uns einfach und erhalten Sie ein spezielles
                Angebot mit 30% Rabatt:
              </span>
            </div>
            <div className='text-center'>
              <AnchorLink
                href='#form'
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/');
                }}
                offset={94}
              >
                <button className={classes.btn}>Angebot erhalten</button>
              </AnchorLink>
              <div
                style={{
                  color: '#BFA260',
                  fontSize: 14,
                  wontWeight: '100',
                  marginTop: 40,
                  marginBottom: 40,
                }}
              >
                (Das Angebot ist begrenzt)
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='mt-3 pt-3'>
            <img className='img-fluid d-block mx-auto' alt='' src={LogoFinal} />
          </Col>
        </Row>
      </Container>
      <div className='my-3 py-5'>
        <p className={classNames(classes.p, 'text-center')}>
          Autor: Andrei Tepliakov
        </p>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center',
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    margin: [80, 0, 0],
  },
  container: {
    backgroundColor: '#DADADA',
  },
  sub: {
    color: '#000',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  sub2: {
    color: '#3F3F3F',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '28px',
    margin: [80, 0],
  },
  p: {
    color: '#3F3F3F',
    fontSize: 18,
    lineHeight: '28px',
  },
  caption: {
    color: '#000000',
    fontSize: 12,
    lineHeight: '14px',
    marginTop: 32,
    textAlign: 'center',
    fontStyle: 'italic',
  },
  gold: {
    color: '#BFA260',
  },
  mainTextBorder: {
    borderTop: '1px solid #BFA260',
    borderBottom: '1px solid #BFA260',
    padding: [40, 0],
  },
  mainText: {
    fontSize: 26,
    lineHeight: '46px',
    fontWeight: 300,
    '& span': {
      color: '#BFA260',
    },
  },
  btn: theme.button.gold,
  quote: {
    borderLeft: '1px solid #BFA260',
    padding: [13, 0, 13, 27],
    [theme.bp.down('md')]: {
      marginLeft: 80,
    },
  },
  arrow: {
    margin: [0, 0, 40],
  },
  final: {
    backgroundColor: '#3F3F3F',
  },
});

export default injectSheet(styles)(Logos);
