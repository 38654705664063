import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { MENU } from '../constants/';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import logo from '../media/logo.svg';
import logoWhite from '../media/Logo_Rey_White 1.svg';
import HeroMobile from '../media/Start_GIF_Mobile.gif';
import toggler from '../media/toggler.svg';
import togglerInverse from '../media/toggler_i.svg';

class Header extends Component {
  state = {
    inverseMenu: false,
  };

  hero = React.createRef();

  handleScroll = (event) => {
    const offsetTop = this.hero.current.offsetTop;
    const heroHeight = this.hero.current.clientHeight;
    const scrollTreshold = offsetTop + heroHeight / 2;
    let scrollTop = window.scrollY;
    const inverseMenu = this.state.inverseMenu;
    if (scrollTop >= scrollTreshold && !inverseMenu) {
      this.setState({ inverseMenu: true });
    } else if (scrollTop < scrollTreshold && inverseMenu)
      this.setState({ inverseMenu: false });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { classes } = this.props;
    const { inverseMenu } = this.state;
    return (
      <>
        <div
          className={classNames(
            'sticky-top',
            classes.menuContainer,
            inverseMenu && classes.inversedMenuContainer
          )}
        >
          <Container>
            <Row>
              <Col
                className={classNames(
                  'py-2 text-center',
                  classes.marginY,
                  inverseMenu && classes.marginYinversed
                )}
                lg={{
                  offset: 5,
                  size: 2,
                }}
              >
                <AnchorLink href='#top'>
                  <img
                    src={inverseMenu ? logoWhite : logo}
                    alt='REYDESIGN.DE'
                  />
                </AnchorLink>
                <Link to='/mobile'>
                  <img
                    className={classNames(
                      classes.toggler,
                      'd-block d-lg-none',
                      inverseMenu && classes.toggler_i
                    )}
                    src={inverseMenu ? togglerInverse : toggler}
                    alt=''
                  />
                </Link>
              </Col>
              <Col
                md={5}
                className={classNames(
                  'py-2 d-lg-flex justify-content-end align-items-end d-none',
                  classes.marginY,
                  inverseMenu && classes.marginYinversed
                )}
              >
                {MENU.map((item, index) => (
                  <AnchorLink
                    key={`${index}-desktop`}
                    className={classNames(
                      classes.menu,
                      'd-flex align-self-end',
                      inverseMenu && classes.inverseMenu,
                      inverseMenu ? classes.link_i : classes.link
                    )}
                    href={item.href}
                    offset={inverseMenu ? item.offset : 176}
                  >
                    {item.text}
                  </AnchorLink>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
        <div ref={this.hero} className={classes.bg}>
          <Container fluid>
            <Row>
              <Col className='text-center'>
                <img src={HeroMobile} className='img-fluid' alt='' />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const style = (theme) => ({
  bg: {
    backgroundColor: '#F3F3F3',
  },
  link: theme.link.light,
  link_i: theme.link.dark,
  menu: {
    fontSize: 13,
    lineHeight: '16px',
    textDecoration: 'underline',
    marginLeft: 11,
    textTransform: 'capitalize',
  },
  inverseMenu: {
    marginTop: 0,
    marginBottom: 0,
    alignSelf: 'center !important',
  },
  menuContainer: {
    backgroundColor: '#fff',
  },
  inversedMenuContainer: {
    backgroundColor: '#3F3F3F',
  },
  marginY: {
    marginTop: 30,
    marginBottom: 30,
    position: 'relative',
  },
  marginYinversed: {
    marginTop: 0,
    marginBottom: 0,
    alignItems: 'center !important',
  },
  toggler: {
    position: 'fixed',
    top: 30,
    right: 30,
  },
  toggler_i: {
    top: 36,
    right: 30,
  },
});

export default injectSheet(style)(Header);
