import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import KolibriLeft from '../media/logos/kalibri_left.jpg';
import KolibriRight from '../media/logos/kalibri_right.jpg';
import { Link } from 'react-router-dom';

const Logos = ({ classes }) => {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className={classes.h2}>Nützliches</h1>
        </Col>
      </Row>
      <Link to='/portfolio/Logos'>
        <Row noGutters className={classes.kolibriAction}>
          <Col
            md={4}
            className='pl-0 pl-md-5 pt-5 pb-5 pb-md-0 d-flex justify-content-center'
          >
            <div className='pb-0 pb-md-5 pb-xl-0'>
              <span className={classes.article}>Artikel:</span>
              <p className={classes.title}>Wie werden Logos erstellt?</p>
              <span className={classes.btn}>erfahren</span>
            </div>
          </Col>
          <Col md={4} className='d-flex align-items-center'>
            <img className='img-fluid' src={KolibriLeft} alt='' />
          </Col>
          <Col md={4} className='d-none d-md-flex align-items-center'>
            <img className='img-fluid' src={KolibriRight} alt='' />
          </Col>
        </Row>
      </Link>
    </Container>
  );
};

const style = (theme) => ({
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    overflow: 'hidden',
    position: 'relative',
    margin: [80, 0, 33],
    '&:before': {
      content: '""',
      borderBottom: '1px solid #D4CFC3',
      width: '50%',
      height: '0.6em',
      position: 'absolute',
      marginLeft: 'calc(-50% - 10px)',
      top: 0,
    },
    '&:after': {
      content: '""',
      borderBottom: '1px solid #D4CFC3',
      width: '50%',
      height: '0.6em',
      position: 'absolute',
      marginLeft: 10,
      top: 0,
    },
  },
  kolibriAction: {
    backgroundColor: '#3F3F3F',
  },
  btn: theme.button.gold,
  title: {
    fontSize: 24,
    lineHeight: '28px',
    color: '#fff',
  },
  article: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 100,
    color: '#fff',
  },
});

export default injectSheet(style)(Logos);
