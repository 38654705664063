import React from 'react';
import { Illustration } from '../../media/dark';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, Row, Col } from 'reactstrap';

function IllustrationContent({ classes, toggle }) {
  return (
    <div>
      <Container fluid>
        <h2 className={classNames(classes.h2Dark, 'text-center mb-3')}>
          Illustration
        </h2>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className={classes.cardText}>
              <img src={Illustration} className='img-fluid' alt='' />
              <div className='mt-3'>
                Wir bieten Ihnen schöne und professionelle Illustrationen, die
                sich perfekt für die Gestaltung von gedruckten (Bücher,
                Zeitschriften, Kataloge, Flyer, Schaufenster, etc.) und
                elektronischen Produkten (Banner, Websites, etc.) eignen.
                <br />
                <br />
                Die Illustrationen werden nach Ihren Angaben hergestellt und
                sind absolut einzigartig.
                <br />
                <br />
                Darüber hinaus bieten wir Ihnen die Herstellung von Fotocollagen
                an.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={classNames('text-center mb-3 pb-3')}>
        <AnchorLink
          className={classes.btn}
          href='#form'
          onClick={toggle}
          offset='94'
        >
          Angebot erhalten
        </AnchorLink>
      </div>
    </div>
  );
}

export default IllustrationContent;
