import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function LogoBanner() {
  return (
    <Container className='mt-3 pt-5'>
      <Row>
        <Col>
          <iframe
            title='Logo banner'
            src={process.env.PUBLIC_URL + '/ReyBanner/index.html'}
            width='100%'
            maxidth='100%'
            height='404px'
            frameBorder='0'
            scrolling='no'
          />
          <AnchorLink
            offset='94'
            href='#form'
            className='stretched-link'
            style={{ cursor: 'pointer' }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LogoBanner;
