import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import header from './media/header.jpg';
import art from './media/art.jpg';
import menu from './media/menu.jpg';
import menu2 from './media/menu2.jpg';
import menu3 from './media/menu3.jpg';

function Menu({ classes }) {
  return (
    <div>
      <div className={classes.header} />
      <Container>
        <Row>
          <Col>
            <h2 className={classes.h2}>Speisekarte</h2>
            <div className='text-center mt-3'>
              <span className={classes.sub}>
                Grafikdesign, Illustration, Layout
              </span>
            </div>
            <div className='text-center mt-3 pt-5'>
              <span className={classes.sub2}>Illustration</span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='mt-2 pt-3'>
        <div className={classes.art} />
      </div>
      <Container>
        <Row>
          <Col>
            <div className='text-center mt-3 pt-5'>
              <span className={classes.sub2}>Layout </span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={classes.layout} />
      <Container>
        <Row>
          <Col>
            <div className={classes.menu2} />
          </Col>
        </Row>
      </Container>
      <Container fluid className={classes.container}>
        <Container>
          <Row>
            <Col>
              <img src={menu3} alt='' className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

const styles = theme => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center'
  },
  layout: {
    height: 606,
    backgroundImage: `url("${menu}")`,
    backgroundPosition: 'center',
    marginTop: 40
  },
  menu2: {
    height: 645,
    backgroundImage: `url("${menu2}")`,
    backgroundPosition: 'center',
    marginTop: 40
  },
  art: {
    height: 362,
    backgroundImage: `url("${art}")`,
    backgroundPosition: 'center'
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0]
  },
  container: {
    backgroundColor: '#F3F3F3'
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold'
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline'
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100
  }
});

export default injectSheet(styles)(Menu);
