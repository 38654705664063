import React from 'react';
import { Input, InputGroup } from 'reactstrap';
import NumberFormat from 'react-number-format';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';

const NumberField = ({ input, meta, ...custom }) => {
  const error = getError(meta);
  const [hover, setHover] = React.useState(false);
  return (
    <InputGroup>
      <NumberFormat
        {...input}
        {...custom}
        autoComplete='off'
        customInput={Input}
        id={custom.id || input.name.replace(/\./g, '-')}
        invalid={!!error}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      <ErrorTooltip
        error={error}
        meta={meta}
        hover={hover}
        target={custom.id || input.name.replace(/\./g, '-')}
      />
    </InputGroup>
  );
};

NumberField.defaultProps = {
  thousandSeparator: ' ',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: false,
  maxLength: 20
};

export default NumberField;
