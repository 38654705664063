import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';
import api from '../api';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#e1cbab' : provided.borderColor,
    '&:hover': {
      borderColor: '#e1cbab'
    },
    outline: state.isFocused ? 0 : provided.outline,
    boxShadow: state.isFocused
      ? '0 0 0 0.2rem rgba(191, 145, 77, 0.25)'
      : provided.boxShadow,
    '&>div': {
      padding: state.isMulti ? '2px 4px' : '2px 8px'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#666666' : 'white',
    borderBottom: '1px solid #666666',
    color: state.isSelected ? 'white' : state.isDisabled ? '#6c757d' : 'black',
    '&:active': {
      backgroundColor: 'white'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 160
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#bf914d',
    padding: '0px 4px',
    '&>div': {
      color: '#fff',
      fontWeight: 600,
      '&~div': {
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#fff'
        }
      }
    }
  })
};

const errorStyles = {
  ...customStyles,
  control: (provided, state) => ({
    ...provided,
    borderColor: '#dc3545',
    '&:hover': {
      borderColor: '#dc3545'
    },
    outline: state.isFocused ? 0 : provided.outline,
    boxShadow: state.isFocused
      ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
      : provided.boxShadow,
    '&>div': {
      padding: state.isMulti ? '2px 4px' : '2px 8px'
    }
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#dc3545',
    padding: '0px 4px',
    '&>div': {
      color: '#fff',
      fontWeight: 600,
      '&~div': {
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#fff'
        }
      }
    }
  })
};

const Option = props => {
  const ref = React.useRef();
  React.useEffect(() => {
    props.isSelected && ref.current.scrollIntoView();
  }, [props.isSelected]);
  return <components.Option {...props} innerRef={ref} />;
};

class SelectFieldAsync extends Component {
  state = {
    hover: false,
    staticError: '',
    menuIsOpen: false,
    data: {}
  };

  componentDidMount() {
    this.loadFiltered('');
  }

  // Получает данные из жукрнала
  getDataFromJournal = ({ value, first = 0, count }) => {
    const { journal, listSize, optionLabel, presets } = this.props;
    return api({
      operation: 'Utils.readFromJournal',
      ns: 'B2C',
      data: JSON.stringify({
        first,
        count: !!count ? count : listSize,
        operation: 'read',
        code: journal,
        source: null,
        pre: presets,
        filters: !!value
          ? { [optionLabel]: { $regex: value, $options: 'i' } }
          : null,
        agg: 1
      })
    });
  };

  // Подгружает отфильтрованные опции при ручном вводе
  loadFiltered = value => {
    const data = this.state.data;
    return this.getDataFromJournal({ value })
      .then(res => {
        const result = res.data;
        if (result.success) {
          const data = result.data;
          this.setState({ data: data });
          return data.values;
        } else {
          return data.values;
        }
      })
      .catch(error => {
        return data.values;
      });
  };

  // Подгружает список авто при скроле вниз
  onListScrollDown = () => {
    const { listSize } = this.props;
    const {
      data: { results, values }
    } = this.state;
    if (results > values.length) {
      this.getDataFromJournal({
        value: '',
        first: values.length,
        count: listSize
      }).then(res => {
        const result = res.data;
        if (result.success) {
          const data = result.data;
          const newData = { ...data, values: [...values, ...data.values] };
          this.setState({
            data: newData
          });
        }
      });
    }
  };

  onMenuOpen = () => {
    const options = this.state.data.values;
    this.setState({ menuIsOpen: true, staticError: '' }, () => {
      if (
        !this.props.input.value ||
        (!options.length && !!this.props.input.value)
      ) {
        this.loadFiltered('');
      }
    });
  };

  onMenuClose = () => {
    this.setState({ menuIsOpen: false });
  };

  setStaticError = staticError => this.setState({ staticError });

  onChangeHandler = value => {
    const {
      processValuesBeforeSelect,
      isMulti,
      input,
      maxItems,
      maxItemsError
    } = this.props;
    const valueProcessed = processValuesBeforeSelect(value);
    if (maxItems && isMulti && !!valueProcessed) {
      if (valueProcessed.length > maxItems) {
        this.setStaticError(maxItemsError(maxItems));
        valueProcessed.splice(-1, 1);
      } else {
        this.setStaticError('');
      }
    }
    input.onChange(valueProcessed);
  };

  render() {
    const { input, meta, options, disabled, readOnly, ...custom } = this.props;
    const { hover, staticError, menuIsOpen, data } = this.state;
    const error = getError(meta);

    return (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <AsyncSelect
          {...input}
          {...custom}
          id={custom.id || input.name.replace(/\./g, '-')}
          onChange={this.onChangeHandler}
          onBlur={(e, value) => input.onBlur(value)}
          options={options}
          blurInputOnSelect={!!error}
          isDisabled={disabled || readOnly}
          getOptionLabel={option => option[custom.optionLabel]}
          getOptionValue={option => option[custom.optionValue]}
          styles={!!error || !!staticError ? errorStyles : customStyles}
          maxMenuHeight={200}
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          loadingMessage={() => 'Загрузка'}
          loadOptions={this.loadFiltered}
          noOptionsMessage={() => 'Нет подходящей марки авто'}
          onMenuScrollToBottom={this.onListScrollDown}
          defaultOptions={data.values || []}
          cacheOptions
          components={{ Option }}
        />
        <ErrorTooltip
          error={
            !!staticError && !menuIsOpen && meta.active ? staticError : error
          }
          meta={meta}
          hover={hover}
          target={custom.id || input.name.replace(/\./g, '-')}
        />
      </div>
    );
  }
}

SelectFieldAsync.defaultProps = {
  options: [],
  disabled: false,
  readOnly: false,
  noOptionsMessage: () => 'Нет опций для выбора',
  optionLabel: 'name',
  optionValue: 'code',
  placeholder: '',
  isMulti: false,
  maxItems: 99,
  maxItemsError: (maxItems = 99) =>
    `Максимальное количество опций - ${maxItems}`,
  processValuesBeforeSelect: values => values,
  journal: '',
  presets: null
};

export default SelectFieldAsync;
