import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-snapshot';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import compose from 'recompose/compose';
import thunk from 'redux-thunk';
import api from './api';
import reducer from './ducks';
import { createBreakpoints } from './utils';

import './index.css';
import './custom.scss';

const theme = {
  bp: createBreakpoints(),
  link: {
    light: {
      color: '#3F3F3F',
      '&:hover': {
        color: '#000',
        '&:active': {
          color: '#f3f3f3',
        },
      },
    },
    dark: {
      color: '#c4c4c4',
      '&:hover': {
        color: '#fff',
        '&:active': {
          color: '#000',
        },
      },
    },
  },
  button: {
    light: {
      backgroundColor: '#C4C4C4',
      display: 'inline-block',
      padding: [16, 0],
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '16px',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: 166,
      color: '#3F3F3F',
      border: 'none',
      outline: 0,
      '&:focus': {
        outline: 0,
      },
      '&:hover': {
        backgroundColor: '#fff',
        textDecoration: 'none',
        color: '#3F3F3F',
        '&:active': {
          outline: 0,
          backgroundColor: '#000',
          color: '#fff',
        },
      },
    },
    dark: {
      backgroundColor: '#3F3F3F',
      display: 'inline-block',
      padding: [16, 0],
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '16px',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: 166,
      color: '#fff',
      border: 'none',
      outline: 0,
      '&:focus': {
        outline: 0,
      },
      '&:hover': {
        backgroundColor: '#000000',
        textDecoration: 'none',
        color: '#fff',
        '&:active': {
          outline: 0,
          backgroundColor: '#F3F3F3',
          color: '#3F3F3F',
        },
      },
    },
    gold: {
      backgroundColor: '#BFA260',
      display: 'inline-block',
      padding: [16, 0],
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '16px',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: 166,
      color: '#fff !important',
      cursor: 'pointer',
      border: 'none',
      outline: 0,
      '&:focus': {
        outline: 0,
      },
      '&:hover': {
        backgroundColor: '#9F8751',
        textDecoration: 'none',
        color: '#fff',
        '&:active': {
          outline: 0,
          backgroundColor: '#F3CC78',
        },
      },
    },
  },
};

const composed = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      applyMiddleware(thunk.withExtraArgument(api)),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : applyMiddleware(thunk.withExtraArgument(api));

const store = createStore(reducer, composed);
render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
