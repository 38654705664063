import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import header from './media/Poster2_Header.jpg';
import img from './media/Illustration_Poster_2.jpg';
import img1 from './media/Illustration_Poster_2_Draw_1.jpg';
import img2 from './media/Illustration_Poster_2_Draw_2.jpg';
import img3 from './media/Illustration_Poster_2_Draw_3.jpg';

function Illustration3({ classes }) {
  return (
    <div>
      <div className={classes.header} />
      <h2 className={classes.h2}>Poster</h2>
      <div className='text-center mt-3 mb-3 pb-5'>
        <span className={classes.sub}>Illustration</span>
      </div>
      <Container>
        <Row>
          <Col>
            <img src={img} alt='' className='img-fluid' />
          </Col>
        </Row>
        <Row className='mt-1 pt-3'>
          <Col md='4' className='mb-1 pb-3'>
            <img src={img1} alt='' className='img-fluid' />
          </Col>
          <Col md='4' className='mb-1 pb-3'>
            <img src={img2} alt='' className='img-fluid' />
          </Col>
          <Col md='4' className='mb-1 pb-3'>
            <img src={img3} alt='' className='img-fluid' />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const styles = theme => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center'
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0]
  },
  container: {
    backgroundColor: '#DADADA'
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold'
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline'
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100
  }
});

export default injectSheet(styles)(Illustration3);
