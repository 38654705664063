import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import header from './media/header.jpg';
import chear from './media//chear.jpg';
import seminar from './media/seminar.jpg';
import plakat from './media/plakat.jpg';
import badge from './media/badge.jpg';

function Furniture({ classes }) {
  return (
    <div>
      <div className={classes.header} />
      <h2 className={classes.h2}>
        Seminar
        <br />
        Die Geschichte der Möbel
      </h2>
      <div className='text-center mt-3'>
        <span className={classes.sub}>Collage, Web-Banner, Plakat</span>
      </div>
      <div className='text-center mt-3 pt-5'>
        <span className={classes.sub2}>Collage </span>
      </div>
      <div className='mt-2 pt-3'>
        <div className={classes.chear} />
      </div>
      <div className='text-center mt-3 pt-5 mb-2 pb-4'>
        <span className={classes.sub2}>Web-Banner </span>
      </div>
      <Container fluid className={classes.container}>
        <Container>
          <Row>
            <Col>
              <img src={seminar} alt='' className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </Container>
      <div className='text-center mt-3 pt-5'>
        <span className={classes.sub2}>Plakat </span>
      </div>
      <div className='mt-2 pt-4'>
        <div className={classes.plakat} />
      </div>
      <Container>
        <Row>
          <Col className={classes.badge}></Col>
        </Row>
      </Container>
    </div>
  );
}

const styles = theme => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center'
  },
  chear: {
    height: 430,
    backgroundImage: `url("${chear}")`,
    backgroundPosition: 'center'
  },
  plakat: {
    height: 643,
    backgroundImage: `url("${plakat}")`,
    backgroundPosition: 'center'
  },
  badge: {
    height: 643,
    backgroundImage: `url("${badge}")`,
    backgroundPosition: 'center'
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0]
  },
  container: {
    backgroundColor: '#F3F3F3'
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold'
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline'
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100
  }
});

export default injectSheet(styles)(Furniture);
