import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import icon1 from '../media/Icon_1_Editorial.svg';
import icon2 from '../media/Icon_2_Webdesign.svg';
import icon3 from '../media/Icon_3_Logo.svg';
import icon4 from '../media/Icon_4_Illustration.svg';
import icon5 from '../media/Icon_5_3D-Modellierung.svg';
import icon6 from '../media/Icon_6_Text 2.svg';
import Becher from '../media/Becher.gif';

const Section2 = ({ classes }) => {
  return (
    <Container fluid className={classes.root}>
      <Container>
        <Row>
          <Col
            md={{
              size: 8,
              offset: 2
            }}
          >
            <h2 className={classes.h2}>Kompetenzen</h2>
            <div className={classes.subh2}>
              Wir bieten unseren Kunden eine breite Palette von Dienstleistungen
              im Bereich der professionellen Grafikdesigns und Web-Entwicklungen
            </div>
            <Row>
              <Col md={3} className='text-center'>
                <Link to='/kompetenzen/Editorial'>
                  <img src={icon1} className='img-fluid' alt='' />
                </Link>
                <Link to='/kompetenzen/Editorial'>
                  <span className={classes.subIcon}>
                    Editorial Designs
                    <br />
                    Druckdesigns
                  </span>
                </Link>
              </Col>
              <Col md={6} className='text-center'>
                <Link to='/kompetenzen/Webdesign'>
                  <img src={icon2} className='img-fluid' alt='' />
                </Link>
                <Link to='/kompetenzen/Webdesign'>
                  <div className={classes.subIcon}>Webdesigns</div>
                </Link>
              </Col>
              <Col md={3} className='text-center'>
                <Link to='/kompetenzen/Logo'>
                  <img src={icon3} className='img-fluid' alt='' />
                </Link>
                <Link to='/kompetenzen/Logo'>
                  <div className={classes.subIcon}>
                    Logo
                    <br />
                    Corporate Designs
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col md={3} className='text-center'>
                <Link to='/kompetenzen/Illustration'>
                  <img src={icon4} className='img-fluid' alt='' />
                </Link>
                <Link to='/kompetenzen/Illustration'>
                  <div className={classes.subIcon}>Illustration</div>
                </Link>
              </Col>
              <Col md={6} className='text-center'>
                <Link to='/kompetenzen/Modellierung'>
                  <img src={icon5} className='img-fluid' alt='' />
                </Link>
                <Link to='/kompetenzen/Modellierung'>
                  <div className={classes.subIcon}>3D-Modellierung</div>
                </Link>
              </Col>
              <Col md={3} className='text-center'>
                <Link to='/kompetenzen/Text'>
                  <img src={icon6} className='img-fluid' alt='' />
                </Link>
                <Link to='/kompetenzen/Text'>
                  <div className={classes.subIcon}>Text</div>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const style = theme => ({
  root: {
    backgroundImage: `url(${Becher})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: [80, 0, 150],
    '& .img-fluid:first-child': {
      cursor: 'pointer'
    }
  },
  h2: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    marginBottom: 20
  },
  h2Dark: {
    color: '#3F3F3F',
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    marginBottom: 20
  },
  subh2: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 100,
    lineHeight: '21px',
    textAlign: 'center',
    marginBottom: 60
  },
  subIcon: {
    margin: [20, 0, 40],
    fontSize: 18,
    fontWeight: 300,
    lineHeight: '21px',
    textAlign: 'center',
    textTransform: 'capitalize',
    color: '#fff',
    minHeight: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  cardText: {
    lineHeight: '21px',
    color: '#000',
    fontWeight: 100,
    minHeight: 459
  }
});

export default injectSheet(style)(Section2);
