import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import Uber from '../media/Uber_Uns_GIF.gif';

const About = ({ classes }) => {
  return (
    <div className='mt-3 pt-5'>
      <Container fluid className={classes.root}>
        <Container>
          <Row>
            <Col
              md={{
                size: 6,
                offset: 3,
              }}
            >
              <h2 className={classes.h2}>Über uns</h2>
              <div className={classes.line} />
              <h2 className={classes.second}>
                Wir machen <span>DAS Design</span>
              </h2>
              <div className={classes.text}>
                Unser Team besteht aus Spezialisten im Bereich Grafikdesign,
                3D-Modellierung und Website-Entwicklern. Wir haben einen Beruf,
                den wir lieben, deshalb ist uns ein interessantes, schönes und
                qualitativ hochwertiges Ergebnis unserer Arbeit sehr wichtig.
              </div>
              <div className={classes.line} />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

const style = (theme) => ({
  root: {
    backgroundImage: `url(${Uber})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: [80, 0, 250],
  },
  h2: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    marginBottom: 110,
  },
  second: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 'bold',
    },
    margin: [80, 0],
  },
  line: {
    border: '1px solid #FFFFFF',
  },
  text: {
    color: '#fff',
    fontWeight: 100,
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'center',
    marginBottom: 80,
  },
});

export default injectSheet(style)(About);
