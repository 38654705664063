import React, { Component } from 'react';
import Select from 'react-select';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#e1cbab' : provided.borderColor,
    '&:hover': {
      borderColor: '#e1cbab'
    },
    outline: state.isFocused ? 0 : provided.outline,
    boxShadow: state.isFocused
      ? '0 0 0 0.2rem rgba(191, 145, 77, 0.25)'
      : provided.boxShadow,
    '&>div': {
      padding: state.isMulti ? '2px 4px' : '2px 8px'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#666666' : 'white',
    borderBottom: '1px solid #666666',
    color: state.isSelected ? 'white' : state.isDisabled ? '#6c757d' : 'black',
    '&:active': {
      backgroundColor: 'white'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 160
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#bf914d',
    padding: '0px 4px',
    '&>div': {
      color: '#fff',
      fontWeight: 600,
      '&~div': {
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#fff'
        }
      }
    }
  })
};

const errorStyles = {
  ...customStyles,
  control: (provided, state) => ({
    ...provided,
    borderColor: '#dc3545',
    '&:hover': {
      borderColor: '#dc3545'
    },
    outline: state.isFocused ? 0 : provided.outline,
    boxShadow: state.isFocused
      ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
      : provided.boxShadow,
    '&>div': {
      padding: state.isMulti ? '2px 4px' : '2px 8px'
    }
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#dc3545',
    padding: '0px 4px',
    '&>div': {
      color: '#fff',
      fontWeight: 600,
      '&~div': {
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#fff'
        }
      }
    }
  })
};

class SelectField extends Component {
  state = {
    hover: false,
    staticError: '',
    menuIsOpen: false
  };

  onMenuOpen = () => {
    this.setState({ menuIsOpen: true, staticError: '' });
  };
  onMenuClose = () => {
    this.setState({ menuIsOpen: false });
  };

  setStaticError = staticError => this.setState({ staticError });

  onChangeHandler = value => {
    const {
      processValuesBeforeSelect,
      isMulti,
      input,
      maxItems,
      maxItemsError
    } = this.props;
    const valueProcessed = processValuesBeforeSelect(value);
    if (maxItems && isMulti && !!valueProcessed) {
      if (valueProcessed.length > maxItems) {
        this.setStaticError(maxItemsError(maxItems));
        valueProcessed.splice(-1, 1);
      } else {
        this.setStaticError('');
      }
    }

    input.onChange(valueProcessed);
  };

  render() {
    const { input, meta, options, disabled, readOnly, ...custom } = this.props;
    const { hover, staticError, menuIsOpen } = this.state;
    const error = getError(meta);

    return (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <Select
          {...input}
          {...custom}
          id={custom.id || input.name.replace(/\./g, '-')}
          onChange={this.onChangeHandler}
          onBlur={(e, value) => input.onBlur(value)}
          options={options}
          blurInputOnSelect={!!error}
          isDisabled={disabled || readOnly}
          getOptionLabel={option => option[custom.optionLabel]}
          getOptionValue={option => option[custom.optionValue]}
          styles={!!error || !!staticError ? errorStyles : customStyles}
          maxMenuHeight={200}
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
        />
        <ErrorTooltip
          error={
            !!staticError && !menuIsOpen && meta.active ? staticError : error
          }
          meta={meta}
          hover={hover}
          target={custom.id || input.name.replace(/\./g, '-')}
        />
      </div>
    );
  }
}

SelectField.defaultProps = {
  options: [],
  disabled: false,
  readOnly: false,
  noOptionsMessage: () => 'Нет опций для выбора',
  optionLabel: 'name',
  optionValue: 'code',
  placeholder: '',
  isMulti: false,
  maxItems: 99,
  maxItemsError: (maxItems = 99) =>
    `Максимальное количество опций - ${maxItems}`,
  processValuesBeforeSelect: values => values
};

export default SelectField;
