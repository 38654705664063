import React from 'react';
import { Webdesign } from '../../media/dark';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, Row, Col } from 'reactstrap';

function WebdesignContent({ classes, toggle }) {
  return (
    <div>
      <Container fluid>
        <h2 className={classNames(classes.h2Dark, 'text-center mb-3')}>
          Webdesigns
        </h2>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className={classes.cardText}>
              <img src={Webdesign} className='img-fluid' alt='' />
              <div className='mt-3'>
                Die Sparte der Webdesigns umfasst die Entwicklung von
                Grafikmaterial für die spätere Veröffentlichung im Internet. Wir
                bieten Ihnen die folgenden Dienstleistungen an:
              </div>
              <div className='my-3 py-3'>
                Corporate Websites
                <br />
                •<br />
                Portfolio Websites
                <br />
                •<br />
                Landingpages
                <br />
                •<br />
                Microsites
                <br />
                •<br />
                Web-Banners
                <br />
                •<br />
                Animierte Web-Banners
                <br />
                <br />
                usw...
                <div className='mt-3'>
                  Wenn Sie nicht wissen, welche Art von Website Sie benötigen,
                  kontaktieren Sie uns einfach, und wir helfen Ihnen:
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={classNames('text-center mb-3 pb-3')}>
        <AnchorLink
          className={classes.btn}
          href='#form'
          onClick={toggle}
          offset='94'
        >
          Angebot erhalten
        </AnchorLink>
      </div>
    </div>
  );
}

export default WebdesignContent;
