import Immutable from 'seamless-immutable';
import { SubmissionError } from 'redux-form';
const prefix = 'App';

const SEND_FORM_BEGIN = `${prefix}/SEND_FORM_BEGIN`;
const SEND_FORM_SUCCEED = `${prefix}/SEND_FORM_SUCCEED`;
const SEND_FORM_FAILED = `${prefix}/SEND_FORM_FAILED`;

const sendFormStart = () => ({
  type: SEND_FORM_BEGIN
});

const sendFormSucceed = () => ({
  type: SEND_FORM_SUCCEED
});

const sendFormFailed = error => ({
  type: SEND_FORM_FAILED,
  error
});

export const sendForm = form => (dispatch, getState, api) => {
  dispatch(sendFormStart());
  return api({
    data: JSON.stringify(form)
  })
    .then(response => {
      const result = response.data;
      if (!result.success) throw new Error(response);
      dispatch(sendFormSucceed(result.data));
      return result;
    })
    .catch(error => {
      const message = (!!error.response && error.response.data.error) || error.message || 'Произошла серверная ошибка'; // prettier-ignore
      dispatch(sendFormFailed(message));
      throw new SubmissionError({ _error: message });
    });
};

const initialState = Immutable({
  formSending: false,
  formSendingError: ''
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_FORM_BEGIN:
      return Immutable.merge(state, {
        formSending: true
      });
    case SEND_FORM_SUCCEED:
      return Immutable.merge(state, {
        formSending: false,
        formSendingError: ''
      });
    case SEND_FORM_FAILED:
      return Immutable.merge(state, {
        formSending: false,
        formSendingError: action.error
      });
    default:
      return state;
  }
};

export default reducer;
