import React from 'react';
import { Input, InputGroup } from 'reactstrap';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';
import { langs } from '../utils';

const TextField = ({
  input,
  meta,
  noCopy,
  trimOnBlur,
  sanitazeOnBlur,
  ...custom
}) => {
  const error = getError(meta);
  const [hover, setHover] = React.useState(false);
  const [staticError, setStaticError] = React.useState('');
  const onChange = e => {
    const { lang } = custom;
    if (!!lang && langs[lang]) {
      const value = e.target.value;
      if (!!value && !langs[lang].regex.test(value)) {
        setStaticError(langs[lang].error);
        return;
      } else {
        setStaticError('');
      }
    }
    input.onChange(e);
  };

  const onBlur = () => {
    let val = input.value;
    if (trimOnBlur && !!val) {
      val = val.trim();
    }
    if (sanitazeOnBlur && !!val) {
      val = val.replace(/^[^A-ZА-Я]+|[^A-ZА-Я]+$/gi, '');
    }
    input.onBlur(val);
    setStaticError('');
  };

  return (
    <InputGroup>
      <Input
        {...input}
        {...custom}
        id={custom.id || input.name.replace(/\./g, '-')}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete='off'
        invalid={!!staticError || !!error}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onPaste={e => (noCopy ? e.preventDefault() : e)}
      />
      <ErrorTooltip
        error={!!staticError && meta.active ? staticError : error}
        hover={hover}
        meta={meta}
        target={custom.id || input.name.replace(/\./g, '-')}
      />
    </InputGroup>
  );
};

export default TextField;
