import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router';
import Header from './Page/Header';
import Section1 from './Page/Section1';
import Section2 from './Page/Section2';
import Portfolio from './Page/Portfolio';
import LogoBanner from './Page/LogoBanner';
import Logos from './Page/Logos';
import About from './Page/About';
import Form from './Page/Form';
import Footer from './Page/Footer';
import Menu from './Page/MobileMenu';
import IconModal from './components/IconModal';
import PortfolioModal from './components/PortfolioModal';
import Seo from './components/Seo';

class App extends React.Component {
  render() {
    return (
      <div id='top'>
        <Switch>
          <Route
            exact
            path='/portfolio/Logos'
            render={(props) => (
              <Seo
                // prettier-ignore
                title='Logo designen lassen [Firmenlogo erstellen lassen] Wie werden Logos erstellt?'
                description='3-Minuten Überblick wie man ein interessantes und professionelles Logo erhält. Wie sieht eigentlich eine professionelle Logogestaltung aus? Gestaltungsraster von Logos.'
                url='https://reydesign.de/portfolio/Logos'
                {...props}
              />
            )}
          />
          <Route render={(props) => <Seo {...props} />} />
        </Switch>
        <Header />
        <Section1 />
        <section id='kompetenzen'>
          <Section2 />
        </section>
        <section id='logoBanner'>
          <LogoBanner />
        </section>
        <section id='portfolio'>
          <Portfolio />
        </section>
        <section id='logos'>
          <Logos />
        </section>
        <section id='about'>
          <About />
        </section>
        <section id='form'>
          <Form />
        </section>
        <section id='contact'>
          <Footer />
        </section>
        <Switch>
          <Route exact path='/mobile' render={(props) => <Menu {...props} />} />
          <Route
            exact
            path='/kompetenzen/:type'
            render={(props) => <IconModal {...props} />}
          />
          <Route
            exact
            path='/portfolio/:name'
            render={(props) => <PortfolioModal {...props} />}
          />
          <Route render={(props) => <Redirect to='/' />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
