import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title, description, url, image, site }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:site_name' content={site} />
      <meta property='og:url' content={url} />
      <meta property='og:description' content={description} />
      <meta property='og:type' content='website' />
      <meta property='og:image' content={image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:image' content={image} />
    </Helmet>
  );
};

Seo.defaultProps = {
  title: 'Wir machen das Design',
  description:
    'Wir bieten unseren Kunden eine breite Palette von Dienstleistungen im Bereich der professionellen Grafikdesigns und Web-Entwicklungen',
  url: 'https://reydesign.de/',
  image: 'https://reydesign.de/og.jpg',
  site: 'REYDESIGN.DE',
};

export default Seo;
