import React, { Component } from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logo from '../media/logo.svg';
import Arrow from '../media/Circle_arrow 1.svg';
import menuBG from '../media/menuBG.jpg';
import { MENU } from '../constants/';

class MobileMenu extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    const { classes, history } = this.props;
    return (
      <div className={classNames(classes.menuContainer, 'd-lg-none d-block')}>
        <div className='my-3 py-3'>
          <img className='d-block mx-auto' src={logo} alt='' />
        </div>
        <img
          className={classes.arrow}
          src={Arrow}
          alt=''
          onClick={(e) => history.goBack()}
        />
        {MENU.map((item, index) => (
          <AnchorLink
            key={`${index}-mobile`}
            className={classes.menuItem}
            href={item.href}
            offset={item.offset}
            onClick={(e) => {
              e.preventDefault();
              history.push('/');
            }}
          >
            {item.text}
          </AnchorLink>
        ))}
        <img src={menuBG} className='img=fluid d-block mx-auto' alt='' />
      </div>
    );
  }
}

const style = (theme) => ({
  menuContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#F3F3F3',
    zIndex: 10000,
    overflowY: 'auto',
  },
  arrow: {
    position: 'absolute',
    top: 30,
    right: 30,
  },
  menuItem: {
    fontWeight: 300,
    fontSize: 20,
    lineHeight: '23px',
    textAlign: 'center',
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
    display: 'block',
    marginBottom: 30,
    color: '#3F3F3F',
    '&:hover': {
      color: '#3F3F3F',
    },
  },
});

export default injectSheet(style)(MobileMenu);
