import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CustomInput } from 'reactstrap';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';

class CheckboxField extends React.Component {
  state = {
    hover: false
  };

  checkbox = React.createRef();

  onChangeHandler = e => {
    e.stopPropagation();
    const { input, formControl } = this.props;
    input.onChange(e);
    if (formControl) {
      this.checkbox.current && this.checkbox.current.focus();
    }
  };

  render() {
    const {
      input,
      meta,
      label,
      readOnly,
      disabled,
      formControl,
      ...custom
    } = this.props;
    const error = getError(meta);
    const { hover } = this.state;
    const divAuxProps = formControl
      ? { id: input.name.replace(/\./g, '-'), tabIndex: '-1' }
      : { id: input.name.replace(/\./g, '-') };

    return (
      <div
        {...divAuxProps}
        ref={this.checkbox}
        className={classNames(
          formControl && 'form-control',
          !!error && 'is-invalid'
        )}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <CustomInput
          {...input}
          {...custom}
          id={custom.id || `${input.name}_custom_input`}
          onChange={this.onChangeHandler}
          label={label}
          disabled={disabled || readOnly}
          autoComplete='off'
          checked={!!input.value}
          invalid={!!error}
        />
        <ErrorTooltip
          error={error}
          meta={meta}
          hover={hover}
          target={custom.id || input.name.replace(/\./g, '-')}
        />
      </div>
    );
  }
}

CheckboxField.defaultProps = {
  label: '',
  readOnly: false,
  disabled: false,
  formControl: true,
  type: 'checkbox'
};

CheckboxField.propTypes = {
  formControl: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['switch', 'checkbox']).isRequired
};

export default CheckboxField;
