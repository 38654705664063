const dev = {
  instance: process.env.REACT_APP_INSTANCE,
  api: `${process.env.REACT_APP_INSTANCE}/api/`
};

const prod = {
  instance: 'https://85.10.234.176',
  api: '../api/'
};

const config = process.env.NODE_ENV === 'production' ? prod : dev;

export default config;
