import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Arrow from '../media/Circle_arrow 1.svg';
import * as Demos from '../Page/Demo';

class PortfolioModal extends Component {
  state = { open: false };

  demo = React.createRef();

  componentDidMount() {
    this.setState({ open: true }, () => {
      document.body.style.overflow = 'hidden';
      this.demo.current.focus();
    });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { match: prevMatch } = prevProps;
    const name = match.params.name;
    const prevName = prevMatch.params.name;
    if (!!prevName && name !== prevName) {
      this.setState({ open: false }, () => this.setState({ open: true }));
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  toggle = () => {
    this.setState({ open: false }, this.props.history.replace('/'));
  };

  onBackdropClick = (e) => {
    const target = e.target;
    if (!this.demo.current.contains(target)) {
      this.toggle();
    }
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.toggle();
    }
  };

  render() {
    const { preview, classes, match, history } = this.props;
    const open = this.state.open;
    const name = match.params.name;
    const Demo = Demos[name];
    return (
      <>
        <div onClick={this.toggle}>{preview}</div>
        {open && (
          <div className={classes.backdrop} onClick={this.onBackdropClick}>
            <div
              ref={this.demo}
              className={classes.root}
              tabIndex='-1'
              onKeyDown={this.onKeyDown}
            >
              <div className={classes.arrow}>
                <img src={Arrow} alt='' onClick={this.toggle} />
              </div>
              <Demo history={history} toggle={this.toggle} />
            </div>
          </div>
        )}
      </>
    );
  }
}

const styles = (thme) => ({
  backdrop: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    overflowY: 'auto',
    backgroundColor: 'rgba(0,0,0,0.25)',
  },
  root: {
    backgroundColor: '#fff',
    maxWidth: 1750,
    margin: '0 auto',
    minHeight: '100%',
    '&:focus': {
      outline: 0,
    },
  },
  arrow: {
    position: 'fixed',
    cursor: 'pointer',
    maxWidth: 'inherit',
    width: '100%',
    textAlign: 'right',
    padding: [30, 30, 0, 0],
    zIndex: 30,
  },
});

export default injectSheet(styles)(PortfolioModal);
