import React from 'react';
import { Editorial } from '../../media/dark';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, Row, Col } from 'reactstrap';

function EditorialContent({ classes, toggle }) {
  return (
    <div>
      <Container fluid>
        <h2 className={classNames(classes.h2Dark, 'text-center mb-3')}>
          Editorial Designs / Druckdesigns
        </h2>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className={classes.cardText}>
              <img src={Editorial} className='img-fluid' alt='' />
              <div className='mt-3'>
                Editorial Designs / Druckdesigns umfassen alle Arten von
                Druckerzeugnissen, wie zum Beispiel:
              </div>
              <div className='my-3 py-3'>
                Anzeigen / Flyer
                <br />
                •<br />
                Speisekarten / Plakate
                <br />
                •<br />
                Visitenkarten / Präsentationen
                <br />
                •<br />
                Broschüren / Kataloge
                <br />
                •<br />
                Etiketten / Verpackungen
                <br />
                •<br />
                Aufkleber / Sticker
                <br />
                •<br />
                Kalender / Außenwerbung
                <br />
                •<br />
                Zeitschriften / Zeitungen
                <br />
                <br />
                usw...
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={classNames('text-center mb-3 pb-3')}>
        <AnchorLink
          className={classes.btn}
          href='#form'
          onClick={toggle}
          offset='94'
        >
          Angebot erhalten
        </AnchorLink>
      </div>
    </div>
  );
}

export default EditorialContent;
