import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CustomInput } from 'reactstrap';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';

class RadiosField extends React.Component {
  field = React.createRef();

  state = {
    hover: false
  };

  onChangeHandler = option => {
    const { input, valueOnly, valueName } = this.props;
    input.onFocus();
    input.onChange(valueOnly ? option[valueName] : option);
    input.onBlur();

    //this.field.current && this.field.current.focus();
  };

  render() {
    const {
      input,
      meta,
      options,
      valueOnly,
      valueName,
      codeName,
      direction,
      readOnly,
      disabled,
      ...custom
    } = this.props;

    const { hover } = this.state;
    const error = getError(meta);
    const auxProps = direction === 'vertical' ? { style: { height: 'auto' } } : {}; // prettier-ignore
    return (
      <div
        id={custom.id || input.name.replace(/\./g, '-')}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        className={classNames(
          direction === 'horizontal' &&
            'd-flex justify-content-between align-self-center',
          !!error && 'is-invalid'
        )}
        ref={this.field}
        {...auxProps}
      >
        {options.map((option, index) => {
          return (
            <CustomInput
              key={`${input.name}-${index}`}
              type='radio'
              id={`${input.name}-${option.code}`}
              name={input.option}
              label={option.name}
              disabled={disabled || readOnly}
              onChange={e => this.onChangeHandler(option)}
              invalid={!!error}
              checked={
                valueOnly
                  ? option[valueName] === input.value
                  : option[codeName] === input.value[codeName]
              }
            />
          );
        })}
        <ErrorTooltip
          error={error}
          meta={meta}
          hover={hover}
          target={custom.id || input.name.replace(/\./g, '-')}
        />
      </div>
    );
  }
}

RadiosField.defaultProps = {
  options: [],
  valueOnly: false,
  codeName: 'code',
  valueName: 'name',
  direction: 'horizontal',
  readOnly: false,
  disabled: false
};

RadiosField.propTypes = {
  options: PropTypes.array.isRequired,
  valueOnly: PropTypes.bool.isRequired,
  codeName: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['vertical', 'horizontal']).isRequired
};

export default RadiosField;
