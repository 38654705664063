import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ButtonGroup } from 'reactstrap';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';

class ButtonsField extends React.Component {
  state = {
    hover: false
  };

  field = React.createRef();

  onClickHandler = option => {
    const { input, valueOnly, valueName } = this.props;
    input.onFocus();
    input.onChange(valueOnly ? option[valueName] : option);
    input.onBlur();
  };

  render() {
    const {
      input,
      meta,
      options,
      valueOnly,
      valueName,
      codeName,
      renderButtonName,
      readOnly,
      disabled,
      vertical,
      block,
      equalWidth,
      ...custom
    } = this.props;
    const error = getError(meta);
    const { hover } = this.state;

    return (
      <div
        id={custom.id || input.name.replace(/\./g, '-')}
        className={classNames(!!error && 'is-invalid')}
        tabIndex={-1}
        ref={this.field}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <ButtonGroup
          vertical={vertical}
          className={classNames(block && 'd-flex')}
        >
          {options.map((option, index) => {
            return (
              <Button
                key={`${input.name}-${index}`}
                id={`${input.name}-${option.code}`}
                className={classNames(equalWidth && 'w-100')}
                type='button'
                color={!!error ? 'danger' : 'primary'}
                name={input.option}
                disabled={disabled || readOnly}
                onClick={e => this.onClickHandler(option)}
                outline={
                  valueOnly
                    ? option[valueName] !== input.value
                    : option[codeName] !== input.value[codeName]
                }
              >
                {!!renderButtonName && typeof renderButtonName === 'function'
                  ? renderButtonName(option)
                  : option[valueName]}
              </Button>
            );
          })}
        </ButtonGroup>
        <ErrorTooltip
          error={error}
          meta={meta}
          hover={hover}
          target={custom.id || input.name.replace(/\./g, '-')}
        />
      </div>
    );
  }
}

ButtonsField.defaultProps = {
  options: [],
  valueOnly: false,
  codeName: 'code',
  valueName: 'name',
  readOnly: false,
  disabled: false,
  vertical: false,
  block: true,
  equalWidth: false
};

ButtonsField.propTypes = {
  options: PropTypes.array.isRequired,
  valueOnly: PropTypes.bool.isRequired,
  codeName: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired
};

export default ButtonsField;
