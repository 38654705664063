import React from 'react';
import { Input, InputGroup } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import ErrorTooltip from '../components/ErrorTooltip';
import getError from './getError';

class PhoneField extends React.Component {
  state = {
    hover: false
  };

  onBlurHandle = () => {
    const { input, placeholderChar } = this.props;
    const regex = new RegExp(`[${placeholderChar}]`);
    if (regex.test(input.value)) {
      input.onChange(undefined);
    }
    input.onBlur();
  };

  render() {
    const { input, meta, ...custom } = this.props;
    const { hover } = this.state;
    const error = getError(meta);
    return (
      <InputGroup>
        <MaskedInput
          {...input}
          {...custom}
          mask={['+','7', '(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]} // prettier-ignore
          id={custom.id || input.name.replace(/\./g, '-')}
          onBlur={this.onBlurHandle}
          autoComplete='off'
          keepCharPositions
          invalid={!!error}
          render={(ref, props) => <Input innerRef={ref} {...props} />}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        />

        <ErrorTooltip
          error={error}
          meta={meta}
          hover={hover}
          target={custom.id || input.name.replace(/\./g, '-')}
        />
      </InputGroup>
    );
  }
}

PhoneField.defaultProps = {
  placeholderChar: '_'
};

export default PhoneField;
