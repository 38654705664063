import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import injectSheet from 'react-jss';
import header from './Aqua_Header.svg';
import logo from './Aqua_Logo.svg';
import Aqua_1 from './Aqua_1.jpg';
import Aqua_2 from './Aqua_2.svg';
import Aqua_3 from './Aqua_3.svg';
import Aqua_4 from './Aqua_4.svg';
import Aqua_5 from './Aqua_5.svg';
import Aqua_6 from './Aqua_6.svg';
import Aqua_7 from './Aqua_7.jpg';
import Logos from '../../Logos';

function Aqua({ classes, toggle }) {
  return (
    <div>
      <div className={classes.header} />
      <h2 className={classes.h2}>Aqua TV</h2>
      <div className='text-center mt-3 mb-3 pb-5'>
        <span className={classes.sub}>Logo Design</span>
      </div>
      <Container>
        <Row>
          <Col
            md={{
              offset: 4,
              size: 4,
            }}
          >
            <img src={logo} alt='' className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <div className='mt-3 pt-5'>
        <img src={Aqua_1} alt='' className='img-fluid' />
      </div>
      <Container className='mb-3 pb-5'>
        <Row>
          <Col md={6}>
            <img src={Aqua_2} alt='' className='img-fluid' />
          </Col>
          <Col md={6}>
            <img src={Aqua_3} alt='' className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#F3F3F3' }}>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <img src={Aqua_4} alt='' className='img-fluid d-block mx-auto' />
          </Col>
        </Row>
      </Container>
      <Container className='my-3 py-5'>
        <Row>
          <Col md={6}>
            <img src={Aqua_5} alt='' className='img-fluid' />
          </Col>
          <Col md={6}>
            <img src={Aqua_6} alt='' className='img-fluid' />
          </Col>
        </Row>
      </Container>
      <div>
        <img src={Aqua_7} alt='' className='img-fluid' />
      </div>
      <div style={{ paddingBottom: 40 }}>
        <div className='text-center mt-3 pt-5 container'>
          <div className={classes.mainText}>
            <span>
              Wünschen Sie sich ein professionelles und interessantes Logo?
              <br />
              Kontaktieren Sie uns einfach und erhalten Sie ein spezielles
              Angebot mit 30% Rabatt:
            </span>
          </div>
          <AnchorLink
            className={classes.btn}
            href='#form'
            offset='94'
            onClick={toggle}
          >
            Angebot erhalten
          </AnchorLink>
          <div
            style={{
              color: '#BFA260',
              fontSize: 14,
              wontWeight: '100',
              marginTop: 40,
              marginBottom: 40,
            }}
          >
            (Das Angebot ist begrenzt)
          </div>
        </div>
        <Logos />
      </div>
    </div>
  );
}

const styles = (theme) => ({
  header: {
    height: 200,
    backgroundImage: `url("${header}")`,
    backgroundPosition: 'center',
  },
  h2: {
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: [80, 0, 0],
  },
  container: {
    backgroundColor: '#DADADA',
  },
  sub: {
    color: '#3F3F3F',
    fontWeight: 'bold',
  },
  sub2: {
    color: '#3F3F3F',
    fontWeight: 'bold',
    textDecoration: ' underline',
  },
  sub3: {
    color: '#3F3F3F',
    fontWeight: 100,
  },
  mainText: {
    fontSize: 26,
    lineHeight: '46px',
    fontWeight: 300,
    '& span': {
      color: '#BFA260',
    },
  },
  btn: { ...theme.button.gold, marginTop: 40 },
});

export default injectSheet(styles)(Aqua);
