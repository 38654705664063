import React, { Component } from 'react';
import { Transition } from 'react-transition-group';
import injectSheet from 'react-jss';
import Arrow from '../media/back.svg';
import Logo from '../media/logo_s.svg';
import Toggler from '../media/toggler_i.svg';
import { Link } from 'react-router-dom';
import * as Modals from '../Page/Skills';

const transitionStyles = {
  entering: { marginRight: 0 },
  entered: { marginRight: 0 },
  exiting: { marginRight: -2000 },
  exited: { marginRight: -2000 },
};

const backdropStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class IconModal extends Component {
  state = { open: false };

  demo = React.createRef();

  componentDidMount() {
    this.setState({ open: true }, () => {
      document.body.style.overflow = 'hidden';
      this.demo.current.focus();
    });
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  toggle = () => {
    this.setState({ open: false });
  };

  onBackdropClick = (e) => {
    const target = e.target;
    if (!this.demo.current.contains(target)) {
      this.toggle();
    }
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
    }
    this.toggle();
  };

  render() {
    const { classes, history, match } = this.props;
    const type = match.params.type;
    const { open } = this.state;
    const Skill = Modals[type];
    return (
      <>
        <Transition
          in={open}
          mountOnEnter
          unmountOnExit
          onExited={(e) => history.replace('/')}
          timeout={500}
        >
          {(state) => (
            <div
              className={classes.backdrop}
              onClick={this.onBackdropClick}
              style={{ ...backdropStyles[state] }}
            >
              <div
                ref={this.demo}
                className={classes.root}
                tabIndex='-1'
                onKeyDown={this.onKeyDown}
                style={{ ...transitionStyles[state] }}
              >
                <div className={classes.menu}>
                  <img src={Arrow} alt='' onClick={this.toggle} />
                  <img src={Logo} alt='' />
                  <Link to='/mobile' className='d-block d-lg-none'>
                    <img src={Toggler} alt='' />
                  </Link>
                  <div className='d-none d-lg-block' />
                </div>
                <div className={classes.content}>
                  <Skill classes={classes} toggle={this.toggle} />
                </div>
              </div>
            </div>
          )}
        </Transition>
      </>
    );
  }
}

const styles = (theme) => ({
  backdrop: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    overflowY: 'auto',
    backgroundColor: 'rgba(0,0,0,0.25)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    transition: `opacity 200ms ease-out`,
    opacity: 0,
  },
  root: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 755,
    minHeight: 844,
    '&:focus': {
      outline: 0,
    },
    display: 'inline-block',
    transition: `margin-right 200ms ease-out`,
    marginRight: -2000,
  },
  menu: {
    position: 'fixed',
    cursor: 'pointer',
    maxWidth: 'inherit',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 30,
    backgroundColor: '#3F3F3F',
    padding: [11, 30],
  },
  btn: theme.button.dark,
  h2Dark: {
    color: '#3F3F3F',
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    marginBottom: 20,
  },
  cardText: {
    lineHeight: '28px',
    color: '#3F3F3F',
    fontWeight: 300,
    minHeight: 591,
    textAlign: 'center',
  },
  content: {
    marginTop: 136,
  },
});

export default injectSheet(styles)(IconModal);
