import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import injectSheet from 'react-jss';
import Foto from '../media/Foto.gif';

const Footer = ({ classes }) => {
  return (
    <>
      <Container fluid className={classes.root}>
        <Container>
          <Row>
            <Col className='text-center'>
              <h2 className={classes.h2}>Kontakt</h2>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <div className={classes.text}>
                Bitte rufen Sie an, schreiben Sie eine E-Mail oder füllen Sie
                das Formular aus, um weitere Informationen von uns zu erhalten:
              </div>
              <div className='mb-2 text-white font-weight-lighter text-center'>
                09244 664 99 21
              </div>
              <div className='text-white font-weight-lighter text-center'>
                <a href='mailto:info@reydesign.de' className={classes.mail}>
                  info@reydesign.de
                </a>
              </div>
              <div className='text-center my-3 py-5'>
                <AnchorLink offset='95' className={classes.btn} href='#form'>
                  Angebot erhalten
                </AnchorLink>
              </div>
              <div className={classes.impressum}>Impressum</div>
              <div className={classes.line} />
              <div className={classes.details}>
                Freiberufler Andrei Tepliakov
                <br />
                Neuhauser Str. 13
                <br />
                91287 Plech
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <div className={classes.copiright}>REYDESIGN 2020</div>
    </>
  );
};

const style = theme => ({
  root: {
    backgroundImage: `url(${Foto})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: [80, 0, 0]
  },
  h2: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    marginBottom: 20
  },
  second: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 100,
    lineHeight: '42px',
    textAlign: 'center',
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 'bold'
    },
    margin: [80, 0]
  },
  line: {
    borderTop: '1px solid #FFFFFF'
  },
  text: {
    color: '#fff',
    fontWeight: 100,
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'center',
    marginBottom: 80
  },
  mail: {
    color: '#fff',
    '&:hover': {
      color: '#fff',
      textDecoration: 'none'
    }
  },
  btn: theme.button.light,
  impressum: {
    textAlign: 'center',
    fontSize: 24,
    lineHeight: '28px',
    fontWeight: '100',
    color: '#fff',
    marginBottom: 20
  },
  details: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: '100',
    color: '#fff',
    marginTop: 20,
    marginBottom: 40
  },
  copiright: {
    backgroundColor: '#3F3F3F',
    color: '#fff',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: '100',
    padding: [40, 0],
    textAlign: 'center'
  }
});

export default injectSheet(style)(Footer);
