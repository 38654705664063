import React from 'react';
import { Text } from '../../media/dark';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, Row, Col } from 'reactstrap';

function TextContent({ classes, toggle }) {
  return (
    <div>
      <Container fluid>
        <h2 className={classNames(classes.h2Dark, 'text-center mb-3')}>Text</h2>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className={classes.cardText}>
              <img src={Text} className='img-fluid' alt='' />
              <div className='mt-3'>
                Unsere professionellen Textspezialisten helfen Ihnen, Ihre
                Marketingbotschaften kompetent zu formulieren und schreiben für
                Sie die Texte von beliebiger Komplexität:
              </div>
              <div className='my-3 py-3'>
                Werbetexte
                <br />
                •<br />
                Kreative Texte
                <br />
                •<br />
                Artikel
                <br />
                •<br />
                Korrekturen
                <br />
                <br />
                usw...
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={classNames('text-center mb-3 pb-3')}>
        <AnchorLink
          className={classes.btn}
          href='#form'
          onClick={toggle}
          offset='94'
        >
          Angebot erhalten
        </AnchorLink>
      </div>
    </div>
  );
}

export default TextContent;
