// import React from 'react';
// import { Tooltip } from 'reactstrap';
import injectSheet from 'react-jss';

function ErrorTooltip({ target, error, meta, hover, classes }) {
  return null;
  // const isOpen =
  //   !!meta && meta.submitFailed ? hover && !!error : hover && !!error;
  // if (!document.getElementById(target)) return null;
  // return (
  //   <Tooltip
  //     isOpen={isOpen}
  //     target={target}
  //     autohide={false}
  //     fade={false}
  //     modifiers={{ computeStyle: { x: 'top' } }}
  //     placement='top'
  //     innerClassName={classes.inner}
  //   >
  //     {error}
  //   </Tooltip>
  // );
}

ErrorTooltip.defaultProps = {
  meta: {},
  hover: false
};

const style = theme => ({
  inner: {
    padding: 10,
    minWidth: 300,
    textAlign: 'left'
  }
});

export default injectSheet(style)(ErrorTooltip);
