import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import injectSheet from 'react-jss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import arrow from '../media/arrow1.svg';

const Section1 = ({ classes }) => {
  return (
    <Container>
      <Row>
        <Col className='my-2 py-4'>
          <h2 className={classes.h2}>Wir machen das Design</h2>
          <AnchorLink offset='94' href='#kompetenzen' className={classes.link}>
            <div className={classes.subh2}>Mehr erfahren</div>
          </AnchorLink>
          <AnchorLink offset='94' href='#kompetenzen'>
            <img className='mx-auto mt-1 d-block' src={arrow} alt='' />
          </AnchorLink>
          <div className='text-center mt-2 pt-4'>
            <AnchorLink offset='94' className={classes.btn} href='#form'>
              Angebot erhalten
            </AnchorLink>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const style = theme => ({
  h2: {
    fontSize: 44,
    fontWeight: 100,
    lineHeight: '52px',
    textAlign: 'center'
  },
  subh2: {
    marginTop: '1rem',
    fontSize: 24,
    fontWeight: 100,
    lineHeight: '28px',
    textAlign: 'center'
  },
  btn: theme.button.dark,
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

export default injectSheet(style)(Section1);
